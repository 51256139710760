import Vue from "vue";
import VueRouter, { Route, RouterOptions, RawLocation, Location } from "vue-router";

import Store from "@/store/";
import * as StatsManager from "@/../common/stats/StatsManager";
import routes from "@/router/routes";
import { LAST_SEEN_DATE_KEY } from "../apm";
import { delay } from "@/../common/utils/utils";

Vue.use(VueRouter);

class MyRouter extends VueRouter {
  private routeHistory: Route[] = [];
  private get hasMoved(): boolean {
    return this.routeHistory.length > 1;
  }

  constructor(options?: RouterOptions) {
    super(options);

    this.afterEach((to, _from) => {
      this.routeHistory.push(to);
    });
  }

  /**
   * Go back to the previous route in the history.
   * @param fallback The fallback route to go to if there is no history. Default is "/".
   */
  public async back(fallback: RawLocation = "/"): Promise<void> {
    const routeBeforeBack = this.currentRoute.fullPath;

    const MAX_BACK_ATTEMPTS = 10;
    let attempts = 0;

    let backWorked = false;
    do {
      this.hasMoved ? this.go(-1) : this.push(fallback);
      await delay(100);
      const routeAfterBack = this.currentRoute.fullPath;

      backWorked = routeBeforeBack !== routeAfterBack;

      attempts++;
    } while (!backWorked && attempts < MAX_BACK_ATTEMPTS);
  }
}

const router = new MyRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    else return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, _from, next) => {
  if (to.meta.skipAuth) return next();
  if (to.params.cgtoken) await Store.dispatch.authentication.setJWT(to.params.cgtoken);
  if (to.query.cgtoken) await Store.dispatch.authentication.setJWT(to.query.cgtoken as string);
  if (to.query.referrer) await Store.dispatch.authentication.setReferrer(to.query.referrer as string);

  if (!Store.getters.authentication.isAuthenticated) {
    // Need to retrieve the cgtoken here because it is deleted in whoami() if it's invalid.
    const hadCGToken = !!Store.getters.authentication.jwt;
    await Store.dispatch.authentication.whoami();
    if (!Store.getters.authentication.isAuthenticated) {
      // Only create a temp account if there's no cgtoken. If it's expired or invalid (if it exists) we show needAccess.
      if (/^TRUE$/i.test(process.env.VUE_APP_CREATE_TEMP_ACCOUNT) && !hadCGToken)
        await Store.dispatch.authentication.createTempAccount();
      else Store.dispatch.authentication.needAccess();
    }
  }
  next();
});

function hasQueryParams(route: Route): boolean {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  if (to.path === from.path) return next();
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    const location: Location = { query: from.query };
    next(Object.assign({}, to, location));
  } else next();
});

router.afterEach((to, from) => {
  localStorage.setItem(LAST_SEEN_DATE_KEY, new Date().toISOString());
  StatsManager.SendNavigationStats(from.fullPath, to.fullPath);
});

router.afterEach(() => {
  Store.dispatch.mobile.updateAndSetManifest();
});

export default router;
