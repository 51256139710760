


















































import { Game } from "@/store/modules/games";
import { Component, Prop, Vue } from "vue-property-decorator";
import * as StatsManager from "@/../common/stats/StatsManager";

@Component
export default class FavButton extends Vue {
  @Prop({ type: Object }) game: Game | undefined;
  @Prop({ type: Boolean, default: false }) stretch: boolean;

  get favorite(): boolean {
    if (!this.game) return false;
    return this.$store.direct.state.games.favoriteGamesIds.includes(this.game._id);
  }

  async toggleFavorite(): Promise<void> {
    if (!this.game) return;
    if (this.favorite) await this.$store.direct.dispatch.games.removeFromFavorite(this.game);
    else await this.$store.direct.dispatch.games.addToFavorite(this.game);
    StatsManager.SendButtonClickStats(location.href, "favorite", { newValue: !this.favorite });
  }
}
