// See https://stackoverflow.com/a/27747377/2780319
// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
/**
 * Convert a decimal number to a hexadecimal string.
 * @param dec The decimal number to convert.
 * @returns The hexadecimal string.
 */
function dec2hex(dec: number): string {
  return dec.toString(16).padStart(2, "0");
}

/**
 * Generate a random id. See https://stackoverflow.com/a/27747377/2780319
 * @param len The length of the id to generate. Defaults to 40.
 * @returns The generated id.
 */
export function generateId(len = 40): string {
  const arr = new Uint8Array(len / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}

/**
 * Generate a random UUID v4.
 * Reference: https://stackoverflow.com/a/61011303/2780319
 * @returns The generated UUID v4.
 */
export function uuidv4(): string {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (s) => {
    const c = Number.parseInt(s, 10);
    return (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16);
  });
}

/**
 * Wait for the given number of milliseconds. It's setTimeout wrapped in a promise.
 * @param ms The number of milliseconds to wait.
 * @returns A promise that resolves after the given number of milliseconds.
 */
export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Assert that the given value is never.
 * @param x The value to assert.
 * @throws If the value is not never.
 */
export function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${x}`);
}

/**
 * Check if the given string is a valid Android Application ID.
 * @param id The Android Application ID to test.
 * @returns True if the given string is a valid Android Application ID, false otherwise.
 */
export function isValidAndroidAppID(id: string): boolean {
  // Should matches Android Application ID following these rules:
  // • It must have at least two segments (one or more dots).
  // • Each segment must start with a letter.
  // • All characters must be alphanumeric or an underscore [a-zA-Z0-9_].
  return /^[a-zA-Z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)+$/.test(id);
}

/**
 * Check if the given string is a valid HTTP URL.
 * @param string The string to test.
 * @returns True if the given string is a valid HTTP URL, false otherwise.
 */
export function isValidHttpUrl(string: string): boolean {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

/**
 * Check if we are running in a web app / pwa.
 * @returns True if the current browser is a web app / pwa, false otherwise.
 */
export function isWebApp(): boolean {
  return (
    (window.navigator as any).standalone == true ||
    window.matchMedia("(display-mode: standalone)").matches ||
    window.matchMedia("(display-mode: fullscreen)").matches
  );
}
