

































































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import { User } from "@/store/modules/authentication";
import { Game } from "@/store/modules/games";
import ListedGame from "@/components/ListedGame.vue";
import OGLTapBar from "@/components/OGLTapBar.vue";
import TapBarMoveWarning from "../components/TapBarMoveWarning.vue";
import LoginForm from "../components/Profile/LoginForm.vue";
import RegisterForm from "../components/Profile/RegisterForm.vue";

@Component({
  components: { ListedGame, OGLTapBar, LoginForm, RegisterForm, TapBarMoveWarning },
})
export default class ProfileView extends Vue {
  loaded = false;
  // username: string | undefined = "";
  friendID = "";
  showLogin = false;
  showRegister = false;
  beforeMovePromise?: (value: boolean | PromiseLike<boolean>) => void = undefined;
  showBeforeMoveWarning = false;

  get games(): Game[] {
    return this.$store.direct.state.games.favoriteGames;
  }

  get hasNotif(): boolean {
    return this.$store.direct.state.mobile.pushNotifEnabled;
  }

  set hasNotif(value: boolean) {
    if (value) {
      if (Notification.permission == "denied") {
        // TODO: something
      }
      this.$store.direct.dispatch.mobile.createNotificationSubscription();
    } else {
      this.$store.direct.dispatch.mobile.removeNotificationSubscription();
    }
  }

  get friendList(): User[] {
    return this.$store.direct.state.authentication.friendList || [];
  }

  get isRegistered(): boolean {
    return this.$store.direct.state.authentication.user?.isRegistered || false;
  }

  get username(): string {
    return this.$store.direct.state.authentication.user?.username || "Anonymous";
  }

  /*
  updateUsername(): void {
    if (!this.username) return;
    this.$store.direct.dispatch.authentication.updateUsername(this.username);
  }
  */

  async addFriend(): Promise<void> {
    await this.$store.direct.dispatch.authentication.addFriend({
      id: this.friendID,
    });
  }

  async removeFriend(friend: User): Promise<void> {
    await this.$store.direct.dispatch.authentication.removeFriend(friend);
  }

  async created(): Promise<void> {
    // await this.$store.direct.dispatch.games.getFavoriteGames();
    // this.username = this.$store.direct.state.authentication.user?.username;
    this.loaded = true;
  }

  deactivated(): void {
    this.showLogin = false;
    this.showRegister = false;
  }

  beforeMoveLeave(): void {
    this.showBeforeMoveWarning = false;
    this.beforeMovePromise?.(true);
  }

  beforeMoveContinue(): void {
    this.showBeforeMoveWarning = false;
    this.beforeMovePromise?.(false);
  }

  beforeMove(location: string, res: (value: boolean | PromiseLike<boolean>) => void): void {
    this.beforeMovePromise = res;
    this.showBeforeMoveWarning = true;
  }
}
