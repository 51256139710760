













































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

import HomeSectionComponent from "@/components/Home/HomeSection.vue";
import TapBar from "@/components/TapBar.vue";
import { HomeSection, Type } from "@/store/modules/homesections";
import { SubscriptionAccess } from "@/store/modules/games";
import GoUltimateButton from "@/components/Common/GoUltimateButton.vue";

let everyVideoElement: Element[] = [];
const intersectSquareHeight = 20;
const screenMiddle = (window.innerHeight / 2) - 40;

function videoNeedsLoading(videoElement: HTMLVideoElement): boolean {
  return videoElement.classList.contains("need-load");
}

function loadVideo(videoElement: HTMLVideoElement): void {
  if (videoElement.classList.contains("need-load")) {
    for (var source in videoElement.children) {
      var videoSource = videoElement.children[source];
      if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
        (videoSource as any).src = (videoSource as any).dataset.src;
      }
    }
    videoElement.load();
    videoElement.classList.remove("need-load");
  }
}

function pauseVideo(videoElement: HTMLVideoElement) {
  videoElement.pause();
}

function playVideo(videoElement: HTMLVideoElement) {
  videoElement.pause();
  videoElement.play();
}

function restartVideo(videoElement: HTMLVideoElement) {
  videoElement.currentTime = 0;
}

function checkIntersection() {
  const top = screenMiddle;
  const bottom = top + intersectSquareHeight;

  let intersectingVideos: Element[] = [];
  let firstVideo: Element | null = null;

  // Get video intersecting with middle and get the first video element
  for (const elem of everyVideoElement) {
    const videoRect = elem.getBoundingClientRect();
    if (!firstVideo) firstVideo = elem;
    else {
      const firstVideoRect = firstVideo.getBoundingClientRect();
      if (videoRect.y < firstVideoRect.y) firstVideo = elem;
    }
    const rectA = { y1: top, y2: bottom };
    const rectB = { y1: videoRect.y, y2: videoRect.bottom };
    if ((rectA.y1 > rectB.y1 && rectA.y1 < rectB.y2) || (rectA.y2 > rectB.y1 && rectA.y2 < rectB.y2)) {
      intersectingVideos.push(elem);
    }
  }

  // If the client is scrolled less than 20 pixels, play the first video and return
  if (window.scrollY <= 20 && firstVideo) {
    const video = firstVideo.querySelector("video");
    if (video) {
      if (video === playing) return;
      if (videoNeedsLoading(video)) {
        loadVideo(video);
        playVideo(video);
      } else {
        playVideo(video);
      }
      if (playing) {
        restartVideo(playing)
        pauseVideo(playing);
      }
      playing = video;
      return;
    }
  }

  // If no video is intersecting, stop playing video and return
  if (intersectingVideos.length === 0) {
    if (playing) {
      restartVideo(playing);
      pauseVideo(playing);
      playing === null;
    }
    return;
  }

  // Play first intersecting video, play old playing video
  const video = intersectingVideos[0].querySelector("video");
  if (video) {
    if (video === playing) return;
    if (videoNeedsLoading(video)) {
      loadVideo(video);
      playVideo(video);
    } else {
      playVideo(video);
    }
    if (playing) {
      restartVideo(playing)
      pauseVideo(playing);
    }
    playing = video;
  }
}

let playing: HTMLVideoElement | null = null;

window.addEventListener("scroll", (event) => {
  checkIntersection();
});

@Component({
  components: {
    HomeSectionComponent,
    GoUltimateButton,
    TapBar,
  },
})
export default class HomeView extends Vue {

  get anonymous(): boolean {
    return this.username === "";
  }

  get username(): string {
    return this.$store.direct.state.authentication.user?.username ?? "";
  }

  get filteredHomeSections(): HomeSection[] {
    return this.$store.direct.state.homeSections.homeSections.filter((h) => h.type && h.type !== Type.OLD);
  }

  get subscribed(): boolean {
    const sub = this.$store.direct.getters.games.getHighestSubscription;
    return !sub || sub.access === SubscriptionAccess.FULL;
  }

  async created(): Promise<void> {
    this.$store.direct.dispatch.homeSections.getHomeSections();
    if (this.$store.direct.getters.mobile.isWebApp)
      await this.$store.direct.dispatch.mobile.createNotificationSubscription();
  }

  mounted() {
    checkIntersection();
  }

  unmounted() {
    if (playing) {
      restartVideo(playing)
      pauseVideo(playing);
      playing = null;
    }
    everyVideoElement = [];
  }

  // When come back to this page, restart and pause every video, then start intersecting video
  activated() {
    playing = null;
    everyVideoElement.forEach((element: Element) => {
      const video = element.querySelector("video");
      if (video) {
        restartVideo(video);
        pauseVideo(video);
      }
    });
    checkIntersection();
  }

  // When we quit this page, restart and pause every video
  deactivated() {
    playing = null;
    everyVideoElement.forEach((element: Element) => {
      const video = element.querySelector("video");
      if (video) {
        restartVideo(video);
        pauseVideo(video);
      }
    });
  }

  // Observe video for playing / pause
  observeVideoCard(element: Element) {
    if (!everyVideoElement.includes(element)) {
      everyVideoElement.push(element);
    }
    if (everyVideoElement.length > 2) {
      checkIntersection();
    }
  }
}
