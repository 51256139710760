




































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import * as StatsManager from "@/../common/stats/StatsManager";

@Component
export default class SharePopup extends Vue {
  close(): void {
    this.$store.direct.commit.mobile.CLOSE_SHARE_POPUP();
    StatsManager.SendButtonClickStats("SHARE_POPUP", "close");
  }

  share(): void {
    this.$store.direct.dispatch.mobile.share();
    this.$store.direct.commit.mobile.CLOSE_SHARE_POPUP();
    StatsManager.SendButtonClickStats("SHARE_POPUP", "share");
  }
}
