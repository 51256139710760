







































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import * as StatsManager from "@/../common/stats/StatsManager";

@Component
export default class SubscribeBannerPopup extends Vue {
  close(): void {
    StatsManager.SendButtonClickStats("SUBSCRIBE_BANNER_POPUP", "close");
    this.$store.direct.commit.mobile.CLOSE_SUBSCRIBE_BANNER_POPUP();
  }

  subscribe(): void {
    StatsManager.SendButtonClickStats("SUBSCRIBE_BANNER_POPUP", "subscribe");
    this.$store.direct.dispatch.games.openSubPopup();
    this.$store.direct.commit.mobile.CLOSE_SUBSCRIBE_BANNER_POPUP();
  }
}
