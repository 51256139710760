

























import Component from "vue-class-component";
import Vue from "vue";

import TagComponent from "@/components/Common/Tag.vue";
import { Tag } from "@/store/modules/tags";

interface TagElement {
  tag: Tag;
  selected: boolean;
}

@Component({
  components: {
    TagComponent,
  },
})
export default class TagScroller extends Vue {
  private isInit = false;
  tagList: TagElement[] = [];

  get tags(): Tag[] {
    return this.$store.direct.state.tags.tags.filter((v) => v.enabled);
  }

  createTags(): void {
    this.tagList = this.tags.map((tag: Tag) => ({ tag, selected: false }));
  }

  async tagClicked(index: number): Promise<void> {
    const previousStatus = this.tagList[index].selected;
    // De-select all tags
    this.tagList.forEach((t) => (t.selected = false));
    // Select the clicked tag
    this.tagList[index].selected = !previousStatus;

    // Emit the selected tag or undefined if the tag was deselected
    this.$emit("update-selected-tag", this.tagList[index].selected ? this.tagList[index].tag : undefined);
    // Use scrollIntoView to make sure the selected tag is visible
    await this.$nextTick();
    if (this.tagList[index].selected) {
      document.querySelector(".tag.selected")?.scrollIntoView();
      // Scroll again after a short delay to make sure the scrollIntoView is executed even on page load
      window.setTimeout(() => {
        // Re-check if the tag is still selected to avoid scrolling to the wrong tag
        if (this.tagList[index].selected) document.querySelector(".tag.selected")?.scrollIntoView();
      }, 100);
    }
  }

  selectUrlTag(): void {
    const tabName = (this.$route.params.tab as string) || process.env.VUE_APP_DEFAULT_CATEGORY || "all";
    const index = this.tagList.findIndex((t) => t.tag.urlSegment === tabName || t.tag.name.toLowerCase() === tabName);
    if (index !== -1) this.tagClicked(index);
    else this.$emit("update-selected-tag", undefined);
  }

  async init(): Promise<void> {
    if (this.isInit) return;
    this.isInit = true;
    if (this.$store.direct.state.tags.tags.length === 0) await this.$store.direct.dispatch.tags.getTags();
    this.createTags();
    this.selectUrlTag();
  }

  async created(): Promise<void> {
    this.init();
  }

  async activated(): Promise<void> {
    this.init();
  }
}
