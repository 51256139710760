


























import Vue from "vue";
import { Component } from "vue-property-decorator";

import { Game } from "@/store/modules/games";
import { StreamingMode } from "@/store/modules/bigscreen";

@Component({
  components: {},
})
export default class Play extends Vue {
  get game(): Game | undefined {
    return this.$store.direct.getters.games.getGame(this.$route.params.game);
  }

  get isBigScreen(): boolean {
    return this.$route.query.streamingmode === StreamingMode.BIGSCREEN;
  }

  get playUrl(): string {
    let url = `/play/?appid=${this.$route.params.game}&cgtoken=${this.$store.direct.getters.authentication.jwt}&eula=true`;
    if (this.isBigScreen) url += `&streamingmode=${StreamingMode.BIGSCREEN}`;
    else url += `&streamingmode=${StreamingMode.MOBILE}`;
    return url;
  }

  init(): void {
    var body = document.body;
    body.classList.add("no-handle-notch");
  }

  reset(): void {
    var body = document.body;
    body.classList.remove("no-handle-notch");
  }

  activated(): void {
    this.init();
  }

  deactivated(): void {
    this.reset();
  }

  mounted(): void {
    this.init();
  }

  beforeDestroy(): void {
    this.reset();
  }
}
