













































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import * as StatsManager from "@/../common/stats/StatsManager";
import { Game } from "@/store/modules/games";

@Component
export default class EnterCodePopup extends Vue {
  @Prop(Object) game: Game;

  codeValue = "";

  hideSuccessMsg = true;
  hideFailMsg = true;

  async play(playOnBigScreen: boolean): Promise<void> {
    StatsManager.SendButtonClickStats(location.href, "play");

    if (!this.game) return;
    if (!this.$store.direct.getters.mobile.isBrowserValid) {
      this.$store.direct.commit.SHOW_ALERT({
        text: this.$t("alert.alerts.bad-browser.text").toString(),
        title: this.$t("alert.alerts.bad-browser.title").toString(),
      });
      StatsManager.SendNavigationStats(location.href, "INVALID_BROWSER_ALERT");
      return;
    }

    this.$store.direct.dispatch.games.play({ game: this.game, playOnBigScreen: playOnBigScreen });
  }

  async checkInput(event: Event) {
    if (event.target && (event.target as any).value.length <= 6) {
      this.codeValue = (event.target as any).value;
    }
    (event.target as any).value = this.codeValue;

    if (this.codeValue.length === 6) {
      const response = await this.$store.direct.dispatch.bigscreen.sendPairingCode(this.codeValue);
      if (response) {
        // Here display success message
        this.hideSuccessMsg = false;
        this.hideFailMsg = true;
        setTimeout(() => {
          this.play(true);
          this.codeValue = "";
          (event.target as any).value = this.codeValue;
          this.close();
        }, 1000);
      } else {
        // Here display failed message
        this.hideSuccessMsg = true;
        this.hideFailMsg = false;
        this.codeValue = "";
        (event.target as any).value = this.codeValue;
      }
    } else {
      this.hideSuccessMsg = true;
      this.hideFailMsg = true;
    }
  }

  close(): void {
    this.$store.direct.commit.mobile.CLOSE_TV_CODE_POPUP();
    StatsManager.SendButtonClickStats("TV_CODE_POPUP", "close");
  }
}
