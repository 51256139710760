import { RouteConfig } from "vue-router";

import { isValidAndroidAppID } from "@/../common/utils/utils";
import Game from "@/views/Game.vue";
import PlayIframe from "@/views/PlayIframe.vue";
import Categories from "@/views/Categories.vue";
import MyGames from "@/views/MyGames.vue";
import Profile from "@/views/Profile.vue";
import Leaderboard from "@/views/Leaderboard.vue";
import Logout from "@/views/Logout.vue";
import Home from "@/views/Home.vue";
import OneGameLobby from "@/views/OneGameLobby.vue";
import OneGameProfile from "@/views/OneGameProfile.vue";

interface MyRouteConfigInterface {
  meta?: {
    noHandleNotch?: boolean;
    skipAuth?: boolean;
  };
}

type MyRouteConfig = MyRouteConfigInterface & RouteConfig;

const routes: MyRouteConfig[] = [];

routes.push(
  ...[
    {
      path: "/game/:game",
      name: "Game",
      component: Game,
      meta: {
        noHandleNotch: false,
      },
    },
    {
      path: "/playiframe/:game",
      name: "PlayIframe",
      component: PlayIframe,
      meta: {
        noHandleNotch: true,
      },
    },
    {
      path: "/category/:tab?",
      name: "Categories",
      component: Categories,
      meta: {
        noHandleNotch: true,
      },
    },
    {
      path: "/mygames",
      name: "My Games",
      component: MyGames,
      meta: {
        noHandleNotch: true,
      },
    },
    {
      path: "/profile/",
      name: "Profile",
      component: isValidAndroidAppID(process.env.VUE_APP_ONE_GAME_LOBBY) ? OneGameProfile : Profile,
    },
    {
      path: "/leaderboard/",
      name: "Leaderboard",
      component: Leaderboard,
    },
    {
      path: "/logout/",
      name: "Logout",
      component: Logout,
    },
    {
      path: "/fakepartnerauth",
      name: "FakePartnerAuth",
      component: () => import("@/views/FakePartnerAuth.vue"),
      meta: {
        skipAuth: true,
      },
    },
    {
      path: "/fakepartnersub",
      name: "FakePartnerSub",
      component: () => import("@/views/FakePartnerSub.vue"),
      meta: {
        title: "Subscribe",
        subscription: {
          type: "subscribe",
        },
      },
    },
    {
      path: "/fakepartnersubtemp",
      name: "FakePartnerSubTemp",
      component: () => import("@/views/FakePartnerSub.vue"),
      meta: {
        title: "Subscribe (New)",
        subscription: {
          type: "subscribe",
        },
      },
    },
    {
      path: "/fakepartnerunsub",
      name: "FakePartnerUnsub",
      component: () => import("@/views/FakePartnerSub.vue"),
      meta: {
        title: "Unsubscribe",
        subscription: {
          type: "unsubscribe",
        },
      },
    },
    {
      path: "/:cgtoken?",
      name: "Home",
      component: isValidAndroidAppID(process.env.VUE_APP_ONE_GAME_LOBBY) ? OneGameLobby : Home,
    },
  ]
);

routes.push(
  ...[
    {
      path: "*",
      redirect: { name: "Home" },
    },
  ]
);

export default routes;
