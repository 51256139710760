import Vue from "vue";
import Vuex from "vuex";
import { createDirectStore } from "direct-vuex";
import { RawLocation } from "vue-router";
import axios from "axios";

import authentication from "./modules/authentication";
import language from "./modules/language";
import games from "./modules/games";
import homeSections from "./modules/homesections";
import gameLists from "./modules/gamelists";
import genres from "./modules/genres";
import tags from "./modules/tags";
import publishers from "./modules/publishers";
import mobile from "./modules/mobile";
import payment from "./modules/payment";
import fastspring from "./modules/payment/fastspring";
import transactbridge from "./modules/payment/transactbridge";
import popup from "./modules/popup";

import * as StatsManager from "@/../common/stats/StatsManager";
import bigscreen from "./modules/bigscreen";

StatsManager.setAPIEndpoint(process.env.VUE_APP_API_ENDPOINT);

Vue.use(Vuex);

interface AlertDialog {
  show: boolean;
  title?: string;
  text: string;
  buttonText?: string;
  onClose?: () => void;
}

interface Add2HomeTuto {
  show: boolean;
  onSkip?: () => void;
  onClose?: () => void;
}

export interface RootState {
  apiEndpoint: string;
  isLoading: boolean;
  requestedRoute: RawLocation;
  alert: AlertDialog;
  showWrongBrowserPopup: boolean;
  add2HomeTuto: Add2HomeTuto;
}

const { store, rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext } = createDirectStore({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    authentication,
    language,
    games,
    mobile,
    homeSections,
    gameLists,
    genres,
    tags,
    publishers,
    payment,
    fastspring,
    transactbridge,
    bigscreen,
    popup,
  },
  state: {
    apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
    isLoading: false,
    requestedRoute: "",
    alert: {
      show: false,
    },
    add2HomeTuto: {
      show: false,
    },
    showWrongBrowserPopup: false,
  } as RootState,
  getters: {},
  mutations: {
    SET_LOADING(state, isLoading: boolean) {
      state.isLoading = isLoading;
    },
    SET_REQUESTED_ROUTE(state, requestedRoute: RawLocation) {
      state.requestedRoute = requestedRoute;
    },
    SHOW_ALERT(state, { title, text, onClose }: { title?: string; text: string; onClose?: () => void }) {
      state.alert.title = title;
      state.alert.text = text;
      state.alert.onClose = onClose;
      state.alert.show = true;
    },
    CLOSE_ALERT(state) {
      if (state.alert.show) {
        state.alert.onClose?.();
        state.alert.show = false;
      }
    },
    SHOW_WRONG_BROWSER_POPUP(state) {
      state.showWrongBrowserPopup = true;
    },
    SHOW_ADD2HOME_TUTO(state, { onSkip, onClose }: { onSkip?: () => void; onClose?: () => void }) {
      state.add2HomeTuto.onSkip = onSkip;
      state.add2HomeTuto.onClose = onClose;
      state.add2HomeTuto.show = true;
      document.querySelector("html")?.classList.add("noscroll");
      window.scrollTo(0, 0);
      StatsManager.SendNavigationStats(location.href, "ADD2HOME_TUTO");
    },
    CLOSE_ADD2HOME_TUTO(state) {
      if (state.add2HomeTuto.show) {
        state.add2HomeTuto.onClose?.();
        state.add2HomeTuto.show = false;
        document.querySelector("html")?.classList.remove("noscroll");
        StatsManager.SendNavigationStats("ADD2HOME_TUTO", location.href);
      }
    },
  },
  actions: {},
});

// Export the direct-store instead of the classic Vuex store.
export default store;

// The following exports will be used to enable types in the
// implementation of actions.
export { rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext };

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module "vuex" {
  interface Store<S> {
    direct: AppStore;
  }
}

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  config.baseURL = store.state.apiEndpoint;
  const token = store.getters.authentication.jwt;
  if (!config.headers.Authorization && token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosInstance.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 429) {
    // TODO Show popup to tell people that they sent too many requests?
  }
  return Promise.reject(error);
});
