







































import { Component, Prop } from "vue-property-decorator";
import CardGame from "./CardGame.vue";
import CardM from "./CardM.vue";

@Component({
  components: {
    CardM,
  },
})
export default class CardGameM extends CardGame {
  @Prop({ type: Boolean, default: false }) showPlayButton: boolean;

  clickPlayButton(): void {
    if (!this.$store.direct.getters.mobile.isBrowserValid) {
      this.$store.direct.commit.SHOW_ALERT({
        text: this.$t("alert.alerts.bad-browser.text").toString(),
        title: this.$t("alert.alerts.bad-browser.title").toString(),
      });
      return;
    }
    this.$store.direct.dispatch.games.play({ game: this.game, playOnBigScreen: false });
  }
}
