
























































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class TapBar extends Vue {
  @Prop({ type: Boolean, default: false }) bordered: boolean;
  @Prop({ type: Boolean, default: false }) checkBeforeMove: boolean;

  async move(location: string): Promise<void> {
    // don't move if we are already there
    if (this.$router.currentRoute.path === location) return;
    let doMove = true;
    if (this.checkBeforeMove) {
      doMove = await new Promise<boolean>((res, _rej) => {
        this.$emit("beforeMove", location, res);
      });
    }
    if (doMove) this.$router.push(location);
  }
}
