






import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class Play extends Vue {
  mounted(): void {
    this.$store.direct.dispatch.authentication.logout();
    // reload page to home
    window.location.href = "/";
  }
}
