import { render, staticRenderFns } from "./TapBarMoveWarning.vue?vue&type=template&id=b0939d12&scoped=true&"
import script from "./TapBarMoveWarning.vue?vue&type=script&lang=ts&"
export * from "./TapBarMoveWarning.vue?vue&type=script&lang=ts&"
import style0 from "./TapBarMoveWarning.vue?vue&type=style&index=0&id=b0939d12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0939d12",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VOverlay})
