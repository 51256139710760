

























































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import ListedGame from "@/components/ListedGame.vue";
import TapBar from "@/components/TapBar.vue";
import TagScroller from "@/components/Discover/TagScroller.vue";

import { Game, SubscriptionAccess } from "@/store/modules/games";

import { Tag } from "@/store/modules/tags";
import GoUltimateButton from "@/components/Common/GoUltimateButton.vue";

@Component({
  components: {
    ListedGame,
    TapBar,
    TagScroller,
    GoUltimateButton,
  },
})
export default class GameListView extends Vue {
  isInit = false;
  loaded = false;
  allGames: Game[] = [];

  games: Game[] = [];

  get subscribed(): boolean {
    const sub = this.$store.direct.getters.games.getHighestSubscription;
    return !sub || sub.access === SubscriptionAccess.FULL;
  }

  /**
   * Check if there is a tag in the URL
   */
  get hasTagInUrl(): boolean {
    // split the URL by slashes, remove empty strings, and check if there are more than 1 segments
    return window.location.pathname.split("/").filter((v) => v).length > 1;
  }

  /**
   * Modify the URL according to the selected tag
   * @param tag The tag to modify the URL with
   */
  modifyUrl(tag: Tag | undefined): void {
    let newUrl: URL;
    // If the tag is defined, use the tag's urlSegment or name to create the new URL
    if (tag) {
      const tagUrlSegment = tag.urlSegment || tag.name.toLowerCase();
      newUrl = new URL(tagUrlSegment, window.location.href);
    }
    // If the tag is undefined, remove the tag from the URL
    else newUrl = new URL(".", window.location.href);

    // Use history.replaceState and not $router.replace to avoid re-rendering the component
    history.replaceState(null, "", newUrl.toString());
  }

  /**
   * Get all games sorted by name
   */
  get sortedGames(): Game[] {
    return this.games.slice().sort((a, b) => {
      if (a.name < b.name) return -1;
      else if (a.name > b.name) return 1;
      else return 0;
    });
  }

  /**
   * Fetch all games from the API with the option to force a fetch to make sure we get all the games.
   * The games are cached so they are retrieved only once for the lifetime of the component.
   */
  async fetchAllGames(): Promise<Game[]> {
    if (this.allGames.length > 0) return this.allGames;
    this.allGames = await this.$store.direct.dispatch.games.getAllGames(true);
    return this.allGames;
  }

  /**
   * Fetch games by tag from the API
   * @param tag The tag to fetch the games for
   */
  async fetchGamesByTag(tag: Tag): Promise<Game[]> {
    return await this.$store.direct.dispatch.games.getGamesByTags(tag);
  }

  /**
   * Fetch tags from the API
   */
  async initTags(): Promise<void> {
    await this.$store.direct.dispatch.tags.getTags();
  }

  /**
   * Update the game list based on the selected tag
   * @param selectedTag The selected tag to filter the games by
   */
  async updatedSelectedTag(selectedTag: Tag | undefined): Promise<void> {
    this.modifyUrl(selectedTag);

    this.loaded = false;
    if (selectedTag) this.games = await this.fetchGamesByTag(selectedTag);
    else this.games = await this.fetchAllGames();
    this.loaded = true;
  }

  async init(): Promise<void> {
    if (this.isInit) return;
    this.isInit = true;

    await this.initTags();

    // If there is no tag in the URL, set the selected tag to undefined to reuse the updatedSelectedTag method
    if (!this.hasTagInUrl) this.updatedSelectedTag(undefined);
    // Else fetch all games in the background
    else this.fetchAllGames();

    // Fetch the home sections in the background
    this.$store.direct.dispatch.homeSections.getHomeSections();
  }

  async created(): Promise<void> {
    await this.init();
  }

  async activated(): Promise<void> {
    await this.init();
  }
}
