





























































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import * as StatsManager from "@/../common/stats/StatsManager";

@Component
export default class SharePopup extends Vue {
  close(): void {
    this.$store.direct.dispatch.authentication.giveAgreement();
    this.$store.direct.commit.mobile.CLOSE_AGREEMENT_POPUP();
    StatsManager.SendButtonClickStats("AGREEMENT_POPUP", "close");
  }

  login(): void {
    this.$store.direct.commit.mobile.CLOSE_AGREEMENT_POPUP();
    this.$store.direct.commit.mobile.SHOW_LOGIN_POPUP(true);
    StatsManager.SendButtonClickStats("AGREEMENT_POPUP", "login");
  }

  get showLogin(): boolean {
    return /^true$/i.test(process.env.VUE_APP_WELCOME_POPUP_SHOW_LOGIN);
  }

  get isPaymentEnabled(): boolean {
    return this.$store.direct.getters.payment.enabled || this.$store.direct.getters.payment.fallbackEnabled;
  }
}
