












































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import { Button } from "@/store/modules/popup";

@Component
export default class GenericPopup extends Vue {
  get title(): string {
    return this.$store.direct.state.popup.title;
  }

  get text(): string {
    return this.$store.direct.state.popup.text;
  }

  get buttons(): Button[] {
    return this.$store.direct.state.popup.buttons;
  }
}
