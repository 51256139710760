import { defineModule } from "direct-vuex";

import { moduleActionContext } from "@/store/";

import * as StatsManager from "@/../common/stats/StatsManager";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const fastspring: any;

interface DataPopupClosed {
  id: string;
  reference: string;
}

const module = defineModule({
  namespaced: true as const,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async setup(context): Promise<void> {
      const { rootDispatch } = moduleActionContext(context, module);

      // ID of the script element
      const scriptId = "fsc-api";
      // Check if the script already exist
      const existingScript = document.getElementById(scriptId);
      if (!existingScript) {
        // Check if the environment variable is set
        if (!process.env.VUE_APP_FASTSPRING_STOREFRONT)
          return console.error("VUE_APP_FASTSPRING_STOREFRONT is not set");

        // Create the script element
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = scriptId;
        script.setAttribute("data-continuous", "true");
        script.src = "https://sbl.onfastspring.com/sbl/1.0.1/fastspring-builder.min.js";
        script.dataset.storefront = process.env.VUE_APP_FASTSPRING_STOREFRONT;

        // Add the callback function
        if (typeof window !== "undefined") {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).dataPopupClosed = (data?: DataPopupClosed) => {
            StatsManager.SendNavigationStats("FASTSPRING_CHECKOUT", window.location.href);

            // Check if the callback was called with data, which means the payment was successful
            if (data && data.id) {
              rootDispatch.payment.onPurchaseCompleted();
              // Otherwise the payment failed or the user closed the popup without completing the payment
            } else rootDispatch.payment.onPurchaseFailed();
          };
        }
        // Add the attribute to the script so the callback function is called
        script.setAttribute("data-popup-closed", "dataPopupClosed");

        // Append the script to the head
        document.head.appendChild(script);
      }
    },
    async purchaseSubscription(context): Promise<void> {
      const { rootState } = moduleActionContext(context, module);
      try {
        const userId = rootState.authentication.user?.id;
        if (!userId) return alert("You must be logged in to subscribe");

        if (!process.env.VUE_APP_FASTSPRING_PRODUCT) return console.error("VUE_APP_FASTSPRING_PRODUCT is not set");

        fastspring.builder.clean();
        fastspring.builder.add(process.env.VUE_APP_FASTSPRING_PRODUCT);
        fastspring.builder.tag({
          user: userId,
        });

        fastspring.builder.checkout();
        StatsManager.SendNavigationStats(window.location.href, "FASTSPRING_CHECKOUT");
      } catch (error) {
        console.error(error);
      }
    },
  },
});

export default module;
