






















































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class CardShowtime extends Vue {
  @Prop(String) readonly title: string;
  @Prop(String) readonly subTitle: string;
  @Prop(String) readonly link: string;
  @Prop(String) readonly imageLink: string;

  get to(): string {
    return this.link ?? "";
  }
}
