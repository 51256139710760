



















































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import debounce from "lodash/debounce";

import i18n from "@/plugins/i18n";

import AlertDialog from "@/components/AlertDialog.vue";
import WrongBrowserPopup from "@/components/WrongBrowserPopup.vue";
import NeedAccessPopup from "@/components/NeedAccessPopup.vue";
import NeedPartnerAuthenticationPopup from "@/components/NeedPartnerAuthenticationPopup.vue";
import Add2HomeTuto from "@/components/Add2HomeTuto.vue";
import Add2HomeAndroidIntercept from "@/components/Add2HomeAndroidIntercept.vue";
import UpdateModal from "@/components/UpdateModal.vue";
import AgreementPopup from "@/components/AgreementPopup.vue";
import SharePopup from "@/components/SharePopup.vue";
import SubscribeTutoPopup from "./components/SubscribeTutoPopup.vue";
import RenewTutoPopup from "./components/RenewTutoPopup.vue";
import SubscribeBannerPopup from "./components/SubscribeBannerPopup.vue";
import LoginPopup from "./components/LoginPopup.vue";
import PayPopup from "./components/PayPopup.vue";
import RegisterPopup from "./components/RegisterPopup.vue";
import PaymentCompletedPopup from "./components/PaymentCompletedPopup.vue";
import PaymentFailedPopup from "./components/PaymentFailedPopup.vue";
import EnterCodePopup from "./components/Bigscreen/EnterCodePopup.vue";
import GenericPopup from "./components/GenericPopup.vue";

import { axiosInstance } from "./store";

import { ErrorCode } from "../sdk/src/engine/types/";
import { ApmBase } from "@elastic/apm-rum";
import { initApm } from "./apm";

@Component({
  name: "App",
  components: {
    AlertDialog,
    WrongBrowserPopup,
    NeedAccessPopup,
    NeedPartnerAuthenticationPopup,
    Add2HomeTuto,
    Add2HomeAndroidIntercept,
    UpdateModal,
    SubscribeTutoPopup,
    RenewTutoPopup,
    AgreementPopup,
    SharePopup,
    SubscribeBannerPopup,
    LoginPopup,
    PayPopup,
    RegisterPopup,
    PaymentCompletedPopup,
    PaymentFailedPopup,
    EnterCodePopup,
    GenericPopup,
  },
})
export default class App extends Vue {
  showGetTempAccountPopup = false;
  apm: ApmBase;

  get shouldKeepAlive(): RegExp | string {
    const shouldKeepAlive = process.env.NODE_ENV === "production";
    return shouldKeepAlive ? "Play" : /.+/;
  }

  async handleRouterParameters(): Promise<void> {
    const store = this.$store.direct;

    if (this.$route.params.cgtoken) await store.dispatch.authentication.setJWT(this.$route.params.cgtoken as string);
    if (this.$route.query.cgtoken) await store.dispatch.authentication.setJWT(this.$route.query.cgtoken as string);
    if (!store.getters.authentication.isAuthenticated) await store.dispatch.authentication.whoami();
  }

  async waitForRouterReady(): Promise<void> {
    return new Promise((res, _rej) => {
      this.$router.onReady(res, (err) => {
        console.warn(err);
        res();
      });
    });
  }

  showError(exitCode: ErrorCode): void {
    switch (exitCode) {
      case ErrorCode.BROWSER_INCOMPATIBLE:
        this.Alert(
          this.$t("alert.alerts.bad-browser.text").toString(),
          this.$t("alert.alerts.bad-browser.title").toString()
        );
        break;
      case ErrorCode.CONNECTION_LOST:
        this.Alert(
          this.$t("alert.alerts.host-error.text").toString(),
          this.$t("alert.alerts.host-error.title").toString()
        );
        break;
      case ErrorCode.CONNECTION_TIMEOUT:
        this.Alert(
          this.$t("alert.alerts.connection-too-long.text").toString(),
          this.$t("alert.alerts.connection-too-long.title").toString()
        );
        break;
      case ErrorCode.NO_CAPACITY_AVAILABLE:
        this.Alert(this.$t("alert.alerts.full.text").toString(), this.$t("alert.alerts.full.title").toString());
        break;
      case ErrorCode.PWA_REQUIRED:
        this.Alert(
          this.$t("alert.alerts.pwa-required.text").toString(),
          this.$t("alert.alerts.pwa-required.title").toString()
        );
        break;
      case ErrorCode.SAVE_PENDING:
        this.Alert(
          this.$t("alert.alerts.previous-save-pending.text").toString(),
          this.$t("alert.alerts.previous-save-pending.title").toString()
        );
        break;
      case ErrorCode.SAVE_RESTORE_ERROR:
        this.Alert(
          this.$t("alert.alerts.save-restore-fail.text").toString(),
          this.$t("alert.alerts.save-restore-fail.title").toString()
        );
        break;
      case ErrorCode.SERVICE_NOT_AVAILABLE:
        this.Alert(
          this.$t("alert.alerts.server-error.text").toString(),
          this.$t("alert.alerts.server-error.title").toString()
        );
        break;
      case ErrorCode.SPEEDTEST_FAILED:
        this.Alert(
          this.$t("alert.alerts.speedtest-fail.text").toString(),
          this.$t("alert.alerts.speedtest-fail.title").toString()
        );
        break;
      case ErrorCode.BS_SPEEDTEST_FAILED:
        this.Alert(
          this.$t("alert.alerts.bs-speedtest-fail.text").toString(),
          this.$t("alert.alerts.bs-speedtest-fail.title").toString()
        );
        break;
      case ErrorCode.USER_AFK:
        this.Alert(this.$t("alert.alerts.afk.text").toString(), this.$t("alert.alerts.afk.title").toString());
        break;
      case ErrorCode.USER_ALREADY_PAIRED:
        this.Alert(
          this.$t("alert.alerts.user-already-paired.text").toString(),
          this.$t("alert.alerts.user-already-paired.title").toString()
        );
        break;
      case ErrorCode.USER_NOT_AUTHORIZED:
        this.Alert(
          this.$t("alert.alerts.not-authorized.text").toString(),
          this.$t("alert.alerts.not-authorized.title").toString()
        );
        break;
      case ErrorCode.USER_UNFOCUS_AFK:
        this.Alert(this.$t("alert.alerts.unfocus.text").toString(), this.$t("alert.alerts.unfocus.title").toString());
        break;
      case ErrorCode.USER_NEW_SESSION:
        this.Alert(
          this.$t("alert.alerts.new-session.text").toString(),
          this.$t("alert.alerts.new-session.title").toString()
        );
        break;
      case ErrorCode.QUEUE_TIMEOUT:
        this.Alert(
          this.$t("alert.alerts.queue-timeout.text").toString(),
          this.$t("alert.alerts.queue-timeout.title").toString()
        );
        break;
      case ErrorCode.BS_DISCONNECTED:
        this.Alert(
          this.$t("alert.alerts.bs-disconnected.text").toString(),
          this.$t("alert.alerts.bs-disconnected.title").toString()
        );
        break;
      case ErrorCode.END_TRIAL:
        this.$store.direct.commit.payment.SHOW_PAY_POPUP();
    }
  }

  async listenToMessages(): Promise<void> {
    window.addEventListener(
      "message",
      (event) => {
        if (event.data.exitCode !== undefined) {
          this.showError(event.data.exitCode);
          const source = event.source as Window;
          const isIframe = source !== source.top;
          const isPopup = !!source.opener;
          if (isIframe) this.$router.back();
          source.close();
        }
      },
      false
    );
  }

  async created(): Promise<void> {
    this.listenToMessages();
    this.dostuff();

    this.apm = await this.setUpApm();

    const pageLoadTransansaction = this.apm.getCurrentTransaction();
    const loadingSpan = pageLoadTransansaction?.startSpan("Loading Animation");
    this.$store.direct.commit.SET_LOADING(true);
    await this.waitForRouterReady();
    await this.$store.direct.dispatch.language.init();
    this.$store.direct.commit.SET_LOADING(false);

    await this.$store.direct.dispatch.payment.setup();

    const promises: Promise<unknown>[] = [];
    const subscriptionStatusPromise = this.$store.direct.dispatch.games.checkAllsubscriptions();
    const expiredSubscriptionsPromise = this.$store.direct.dispatch.games.getExpiredSubscriptions();
    setInterval(() => {
      this.$store.direct.dispatch.games.checkAllsubscriptions();
    }, parseInt(process.env.VUE_APP_GLOBAL_ACCESS_UPDATE_INTERVAL) || 1000 * 60);
    promises.push(subscriptionStatusPromise);
    promises.push(expiredSubscriptionsPromise);
    promises.push(this.$store.direct.dispatch.mobile.init());

    subscriptionStatusPromise.then(() => {
      if (!this.$store.direct.getters.games.hasSubscription)
        this.$store.direct.dispatch.authentication.needSubscription();
    });

    if (!this.$store.direct.getters.mobile.gaveAgreement) {
      this.$store.direct.commit.mobile.SHOW_AGREEMENT_POPUP();
    }

    axiosInstance.get("/server-status").catch((err) => {
      if (!err.response) {
        this.$store.direct.commit.SHOW_ALERT({
          title: i18n.t("maintenance-alert.title").toString(),
          text: i18n.t("maintenance-alert.text").toString(),
          onClose: () => {
            location.reload();
          },
        });
        return;
      }
    });

    try {
      await Promise.all<unknown>(promises);
    } catch (e) {
      console.error(e);
    }

    const exitCode = new URL(window.location.href).searchParams.get("exitCode");
    if (exitCode) {
      this.showError(parseInt(exitCode, 10));
      let query = Object.assign({}, this.$route.query);
      delete query.exitCode;
      this.$router.replace({ query });
    }
    this.$store.direct.commit.SET_LOADING(false);
    loadingSpan?.end();
    const loadedLobby = this.apm.startTransaction("Loaded-lobby", "usage-tracking");
    setTimeout(() => {
      loadedLobby?.end();
    }, 10);

    if (this.$store.direct.getters.mobile.isWebApp) {
      setInterval(this.checkPWANeedReload, 1000);
      this.checkPWANeedReload();

      this.$store.direct.commit.mobile.INCREMENT_PWA_LAUNCHES();
      const countPWALaunches = this.$store.direct.getters.mobile.countPWALaunches;

      if (process.env.VUE_APP_HIDE_NATIVE_PWA_BANNER > 0) {
        if (countPWALaunches > 0 && countPWALaunches % process.env.VUE_APP_PWA_LAUNCHES_FOR_SHARE_POPUP == 0) {
          this.$store.direct.commit.mobile.SHOW_SHARE_POPUP();
        }
      }
    }

    window.addEventListener("beforeinstallprompt", (e: BeforeInstallPromptEvent) => {
      const hideNativePWABanner = /^true$/i.test(process.env.VUE_APP_HIDE_NATIVE_PWA_BANNER);
      if (hideNativePWABanner) e.preventDefault();
      this.$store.direct.commit.mobile.SET_DEFERRED_PWA_PROMPT(e);
    });
  }

  async checkPWANeedReload(): Promise<void> {
    await this.$store.direct.dispatch.mobile.checkPWAChanged();
  }

  dostuff(): void {
    this.setViewHeight();

    const debouncedSetHeight = debounce(this.setViewHeight, 50);

    window.addEventListener("resize", debouncedSetHeight);

    this.$once("destroyed", () => {
      window.removeEventListener("resize", debouncedSetHeight);
    });
  }

  setViewHeight(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  private Alert(message: string, title = ""): void {
    this.$store.direct.commit.SHOW_ALERT({
      title,
      text: message,
    });
  }

  private async setUpApm(): Promise<ApmBase> {
    const sessionExpirationDuration = !isNaN(Number(process.env.VUE_APP_SESSION_EXPIRATION_DURATION))
      ? Number(process.env.VUE_APP_SESSION_EXPIRATION_DURATION)
      : 300;
    const apmEndpoint = process.env.VUE_APP_APM_ENDPOINT ? process.env.VUE_APP_APM_ENDPOINT : "https://localhost:8200";
    const active = process.env.VUE_APP_APM_ACTIVE ? process.env.VUE_APP_APM_ACTIVE : false;
    return initApm(apmEndpoint, sessionExpirationDuration, [this.$store.direct.state.apiEndpoint], active);
  }
}
