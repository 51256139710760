






















































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import * as StatsManager from "@/../common/stats/StatsManager";

interface TutoLine {
  icon?: string;
  useLogo?: boolean;
  text: string;
}

@Component
export default class Add2HomeTuto extends Vue {
  showButtonClose = true;

  get showButtonSkip(): boolean {
    return /^true$/i.test(process.env.VUE_APP_PWA_TUTO_SKIP_BUTTON_ANDROID);
  }

  skip(): void {
    this.$store.direct.state.mobile.add2HomeAndroidIntercept.onSkip?.();
    this.$store.direct.commit.mobile.CLOSE_SHOW_ADD2HOME_ANDROID_INTERCEPT();
    StatsManager.SendButtonClickStats("ADD2HOME_ANDROID_INTERCEPT", "skip");
  }

  close(): void {
    this.$store.direct.state.mobile.add2HomeAndroidIntercept.onClose?.();
    this.$store.direct.commit.mobile.CLOSE_SHOW_ADD2HOME_ANDROID_INTERCEPT();
    StatsManager.SendButtonClickStats("ADD2HOME_ANDROID_INTERCEPT", "close");
  }

  install(): void {
    this.$store.direct.commit.mobile.SHOW_DEFERRED_PWA_PROMPT();
    this.$store.direct.commit.mobile.CLOSE_SHOW_ADD2HOME_ANDROID_INTERCEPT();
    StatsManager.SendButtonClickStats("ADD2HOME_ANDROID_INTERCEPT", "install");
  }
}
