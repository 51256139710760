


















































































































































































































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

import { Game } from "@/store/modules/games";

import CardScreenshot from "@/components/CardScreenshot.vue";
import CardGameL from "@/components/CardGameL.vue";
import HomeScroller from "@/components/HomeScroller.vue";
import OGLTapBar from "@/components/OGLTapBar.vue";
import { Genre } from "@/store/modules/genres";

@Component({
  components: {
    CardScreenshot,
    CardGameL,
    CoolLightBox,
    HomeScroller,
    OGLTapBar,
  },
})
export default class GameView extends Vue {
  currentSShot = null;
  fullDescription = false;

  get info() {
    return [
      {
        key: this.$t("game.sections.info.publisher"),
        value: this.publisher,
      },
    ];
  }

  get screenshots(): string[] {
    return this.game?.screenshots?.map((screenshot) => screenshot.url) || [];
  }

  get favorite(): boolean {
    if (!this.game) return false;
    return this.$store.direct.state.games.favoriteGamesIds.includes(this.game._id);
  }

  get isPortrait(): boolean {
    return this.game?.orientation === "PORTRAIT";
  }

  async play(): Promise<void> {
    if (!this.game) return;
    if (!this.$store.direct.getters.mobile.isBrowserValid) {
      this.$store.direct.commit.SHOW_ALERT({
        text: this.$t("alert.alerts.bad-browser.text").toString(),
        title: this.$t("alert.alerts.bad-browser.title").toString(),
      });
      return;
    }
    this.$store.direct.dispatch.games.play({ game: this.game, playOnBigScreen: false });
  }

  async toggleFavorite(): Promise<void> {
    if (!this.game) return;
    if (this.favorite) await this.$store.direct.dispatch.games.removeFromFavorite(this.game);
    else await this.$store.direct.dispatch.games.addToFavorite(this.game);
  }

  get pegiRating(): number {
    return this.game?.contentRating?.pegi?.rating || 3;
  }

  get game(): Game | undefined {
    return this.$store.direct.getters.games.getGame(process.env.VUE_APP_ONE_GAME_LOBBY);
  }

  get publisher(): string {
    return this.$store.direct.state.publishers.publishers.find((p) => p._id === this.game?.publisher)?.name || "";
  }

  get genre(): Genre | undefined {
    return this.$store.direct.state.genres.genres.find((p) => p._id === this.game?.genre);
  }

  get genreName(): string {
    return this.$te(`genres.${this.genre?._id}.name`) ? this.$t(`genres.${this.genre?._id}.name`).toString() : "";
  }

  get genreLink(): string {
    return `/category/${this.genre?.urlSegment || this.genre?.name.toLowerCase()}`;
  }

  get contentRating(): string {
    if (this.game?.contentRating.pegi.rating) return `PEGI ${this.game?.contentRating.pegi.rating}`;
    return "";
  }

  get canShare(): boolean {
    return this.$store.direct.getters.mobile.canShare;
  }

  async share(): Promise<void> {
    if (!this.game) return;
    return this.$store.direct.dispatch.mobile.share({ game: this.game });
  }

  async created(): Promise<void> {
    await this.$store.direct.dispatch.games.getGameByAppID(process.env.VUE_APP_ONE_GAME_LOBBY);
    if (!this.game) return;
    await this.$store.direct.dispatch.genres.getGenres();
    await this.$store.direct.dispatch.publishers.getPublishers();

    if (this.$store.direct.getters.mobile.isWebApp)
      await this.$store.direct.dispatch.mobile.createNotificationSubscription();
  }
}
