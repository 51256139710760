















































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import * as StatsManager from "@/../common/stats/StatsManager";

interface TutoLine {
  icon?: string;
  useLogo?: boolean;
  text: string;
}

@Component
export default class Add2HomeTuto extends Vue {
  showButtonClose = true;

  get isChrome(): boolean {
    return this.$store.direct.getters.mobile.isChrome;
  }

  get isAndroid(): boolean {
    return this.$store.direct.getters.mobile.isAndroid;
  }

  get isSafari(): boolean {
    return this.$store.direct.getters.mobile.isSafari;
  }

  get isiOS(): boolean {
    return this.$store.direct.getters.mobile.isiOS;
  }

  get showButtonSkip(): boolean {
    if (this.isAndroid) return /^true$/i.test(process.env.VUE_APP_PWA_TUTO_SKIP_BUTTON_ANDROID);
    if (this.isiOS) return /^true$/i.test(process.env.VUE_APP_PWA_TUTO_SKIP_BUTTON_IOS);
    return false;
  }

  get tutoLines(): TutoLine[] {
    const res: TutoLine[] = [];
    if (this.isAndroid) {
      res.push(
        {
          icon: "$add2HomeTutoAndroidChromeStep1",
          text: this.$t("add2hometuto.tuto.android.chrome.step1").toString(),
        },
        {
          icon: "$add2HomeTutoAndroidChromeStep2",
          text: this.$t("add2hometuto.tuto.android.chrome.step2").toString(),
        }
      );
    } else if (this.isiOS) {
      res.push(
        {
          icon: "$add2HomeTutoOtherStep1",
          text: this.$t("add2hometuto.tuto.other.step1").toString(),
        },
        {
          icon: "$add2HomeTutoOtherStep2",
          text: this.$t("add2hometuto.tuto.other.step2").toString(),
        }
      );
    }
    res.push({
      useLogo: true,
      text: this.$t("add2hometuto.tuto.lastStep").toString(),
    });
    return res;
  }

  skip(): void {
    this.$store.direct.state.add2HomeTuto.onSkip?.();
    this.$store.direct.commit.CLOSE_ADD2HOME_TUTO();
    StatsManager.SendButtonClickStats("ADD2HOME_TUTO", "skip");
  }

  close(): void {
    StatsManager.SendButtonClickStats("ADD2HOME_TUTO", "close");
    this.$store.direct.commit.CLOSE_ADD2HOME_TUTO();
  }
}
