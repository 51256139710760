import { render, staticRenderFns } from "./OGLTapBar.vue?vue&type=template&id=52b1db22&scoped=true&"
import script from "./OGLTapBar.vue?vue&type=script&lang=ts&"
export * from "./OGLTapBar.vue?vue&type=script&lang=ts&"
import style0 from "./OGLTapBar.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./OGLTapBar.vue?vue&type=style&index=1&id=52b1db22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52b1db22",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBottomNavigation,VBtn,VIcon})
