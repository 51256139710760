








































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import i18n from "@/plugins/i18n";

@Component
export default class LoginForm extends Vue {
  @Prop({ type: Boolean, default: true }) showCloseButton!: boolean;

  form = {
    valid: false,
    working: false,
    login: "",
    password: "",
    showPassword: false,
    error: "",
  };

  formRules = {
    required: (value: string) => !!value || i18n.t("profile.registration.login.form.errors.required"),
  };

  $refs!: {
    form: HTMLFormElement;
  };

  resetError(): void {
    this.form.error = "";
  }

  async login(): Promise<void> {
    this.$refs.form.validate();
    if (!this.form.valid) return;

    if (this.form.working) return;
    this.form.working = true;

    const [_, loginResult] = await Promise.all([
      new Promise((resolve) => setTimeout(resolve, 1000)),
      this.$store.direct.dispatch.authentication.login({
        login: this.form.login,
        password: this.form.password,
      }),
    ]);
    if (!loginResult) {
      this.form.error = this.$t("profile.registration.login.form.errors.invalid").toString();
    } else {
      this.$emit("logged");
    }
    this.form.working = false;
  }
}
