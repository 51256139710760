











































































































import { Component, Prop } from "vue-property-decorator";

import CardGame from "@/components/CardGame.vue";
import HomeListedGame from "@/components/HomeListedGame.vue";
import * as StatsManager from "@/../common/stats/StatsManager";
import { SubscriptionAccess } from "@/store/modules/games";
import PlayButton from "@/components/Common/PlayButton.vue";

@Component({
  components: {
    HomeListedGame,
    PlayButton,
  },
})
export default class HomeCardGameVideo extends CardGame {
  @Prop(Boolean) readonly disableLink: boolean;
  @Prop(Boolean) readonly small: boolean;

  accessUpdateInterval: number | null = null;
  @Prop(String) readonly subTitle: string;
  hidden = true;

  get videoURL(): string {
    return this.game.headerVideo;
  }

  playButtonText(): string {
    if (this.game.isComingSoon) return this.$t(`game.buttons.play.coming-soon`).toString();
    if (!this.gameSubscription) return "";
    return this.$t(`game.buttons.play.${this.gameSubscription}`).toString();
  }

  get noActiveSub(): boolean {
    return this.gameSubscription === SubscriptionAccess.VISIBLE;
  }

  get gameSubscription(): SubscriptionAccess | undefined {
    if (!this.game) return undefined;
    return this.$store.direct.getters.games.getGameSubscription(this.game) || undefined;
  }

  async play(e: Event): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    if (!this.game) return;

    StatsManager.SendButtonClickStats(location.href, "play", {
      game: {
        name: this.game.name,
        appID: this.game.appID,
        isComingSoon: this.game.isComingSoon,
      },
      gameSubscription: this.gameSubscription,
    });

    if (this.game?.isComingSoon) return;

    if (!this.$store.direct.getters.mobile.isBrowserValid) {
      this.$store.direct.commit.SHOW_ALERT({
        text: this.$t("alert.alerts.bad-browser.text").toString(),
        title: this.$t("alert.alerts.bad-browser.title").toString(),
      });
      return;
    }
    this.$store.direct.dispatch.games.play({ game: this.game, playOnBigScreen: false });
  }

  setupAccessUpdate(): void {
    if (this.accessUpdateInterval) return;
    this.accessUpdateInterval = setInterval(() => {
      if (this.game) this.$store.direct.dispatch.games.checkGameSubscription({ game: this.game, force: true });
    }, parseInt(process.env.VUE_APP_GAME_ACCESS_UPDATE_INTERVAL) || 1000 * 60);
  }

  activated(): void {
    if (this.game) this.$store.direct.dispatch.games.checkGameSubscription({ game: this.game, force: true });
    this.setupAccessUpdate();
  }

  deactivated(): void {
    this.$el.querySelector("video")?.pause();
    if (this.accessUpdateInterval) {
      clearInterval(this.accessUpdateInterval);
      this.accessUpdateInterval = null;
    }
  }

  async created(): Promise<void> {
    if (this.game) await this.$store.direct.dispatch.games.checkGameSubscription({ game: this.game });
    this.setupAccessUpdate();
  }

  mounted(): void {
    const t = this.$el.querySelector("video");
    t?.addEventListener("canplay", (event) => {
      StatsManager.SendArbitraryStats("home-video-canplay", {
        appId: this.game.appID,
        videoUrl: this.game.headerVideo,
      });
    });
    t?.addEventListener("canplaythrough", (event) => {
      StatsManager.SendArbitraryStats("home-video-canplaythrough", {
        appId: this.game.appID,
        videoUrl: this.game.headerVideo,
      });
    });
  }
}
