class Logger {
  private enabled: boolean;
  private prefix = "CG-SDK";

  public setPrefix(prefix?: string): void {
    this.prefix = prefix || "";
  }

  public setEnabled(enabled: boolean): void {
    this.enabled = enabled;
  }

  private modifyLog(args: unknown[]): unknown[] {
    const newArgs = Array.prototype.slice.call(args);

    if (this.prefix) newArgs.unshift(`[${this.prefix}] -`);

    return newArgs;
  }

  public log(...args: unknown[]): void {
    if (this.enabled) console.log(...this.modifyLog(args));
  }

  public info(...args: unknown[]): void {
    if (this.enabled) console.info(...this.modifyLog(args));
  }

  public debug(...args: unknown[]): void {
    if (this.enabled) console.debug(...this.modifyLog(args));
  }

  public warn(...args: unknown[]): void {
    if (this.enabled) console.warn(...this.modifyLog(args));
  }

  public error(...args: unknown[]): void {
    if (this.enabled) console.error(...this.modifyLog(args));
  }
}

export default new Logger();
