import Vue from "vue";

import App from "@/App.vue";
// import "@/registerServiceWorker";
if (window.isSecureContext)
  navigator?.serviceWorker?.register("/serviceworker.js").catch((err) => {
    console.warn("Service Worker Registration Failed: ", err);
  });
else console.warn("Service Worker Registration Failed: Not a secure context");

import router from "@/router";
import store from "@/store";

import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";

Vue.config.productionTip = false;

Vue.config.errorHandler = function (err, _vm, _info) {
  console.error(err);
};

new Vue({
  router,
  store: store.original,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
