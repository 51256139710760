import { defineModule } from "direct-vuex";
import { moduleActionContext } from "..";
import { axiosInstance as axios } from "@/store/";
import { AxiosResponse } from "axios";

export enum StreamingMode {
  BIGSCREEN = "BIGSCREEN",
  MOBILE = "MOBILE",
}

const module = defineModule({
  namespaced: true as const,
  state: {
    bigscreenIsPaired: false,
  },
  getters: {
    isBigscreenPaired: (state) => state.bigscreenIsPaired,
  },
  mutations: {
    TOGGLE_BIGSCREEN_IS_PAIRED(state, bool: boolean) {
      state.bigscreenIsPaired = bool;
    },
  },
  actions: {
    async sendPairingCode(context, code: string) {
      const { commit } = moduleActionContext(context, module);

      const body = { pairing_code: code };
      let response: AxiosResponse;
      try {
        response = await axios.post(`/api/v1/bigScreen/pairingCode`, body);
      } catch (err) {
        console.error(err);
        commit.TOGGLE_BIGSCREEN_IS_PAIRED(false);
        return false;
      }
      if (response.status === 200) {
        commit.TOGGLE_BIGSCREEN_IS_PAIRED(true);
        return true;
      } else {
        commit.TOGGLE_BIGSCREEN_IS_PAIRED(false);
        return false;
      }
    },

    async isPairedToBigScreen(context) {
      const { commit } = moduleActionContext(context, module);
      try {
        const response = await axios.get(`/api/v1/bigScreen/isPaired`);
        if (response.status === 200) {
          commit.TOGGLE_BIGSCREEN_IS_PAIRED(true);
        }
      } catch (e) {
        commit.TOGGLE_BIGSCREEN_IS_PAIRED(false);
      }
    },
  },
});

export default module;
