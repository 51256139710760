
















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class WrongBrowserPopup extends Vue {
  @Prop(Boolean) show: boolean;
}
