






















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Game } from "@/store/modules/games";

@Component
export default class Banner extends Vue {
  @Prop(Object) readonly game: Game;
  @Prop(String) readonly to: string | undefined;

  get loaded(): boolean {
    return !!this.game;
  }
}
