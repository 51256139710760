export enum ErrorCode {
  /**
   * The current browser is not compatible with CareGame.
   */
  BROWSER_INCOMPATIBLE,

  /**
   * The connection to the CareGame service has been lost.
   */
  CONNECTION_LOST,

  /**
   * The connection to the CareGame service took to long to succeed and has been aborted.
   */
  CONNECTION_TIMEOUT,

  /**
   * No capacity was found to serve this user.
   */
  NO_CAPACITY_AVAILABLE,

  /**
   * The current browser requires the page to be running as a PWA but the page is not running as such.
   */
  PWA_REQUIRED,

  /**
   * The previous save of the user is still being saved.
   */
  SAVE_PENDING,

  /**
   * The previous save of the user could not be retrieved.
   */
  SAVE_RESTORE_ERROR,

  /**
   * The CareGame service is not available.
   */
  SERVICE_NOT_AVAILABLE,

  /**
   * The speedtest showed that the user doesn't have a good enough connection to play.
   */
  SPEEDTEST_FAILED,

  /**
   * The speedtest showed that the user doesn't have a good enough connection to play on bigscreen.
   */
  BS_SPEEDTEST_FAILED,

  /**
   * The user has not touched the screen for a while and has been set as AFK.
   */
  USER_AFK,

  /**
   * The user is already playing on another device.
   */
  USER_ALREADY_PAIRED,

  /**
   * The user is trying to play a game they don't have access to.
   */
  USER_NOT_AUTHORIZED,

  /**
   * The user has switch app and has been set as AFK.
   */
  USER_UNFOCUS_AFK,

  /**
   * A new session has been launched with the same account.
   */
  USER_NEW_SESSION,

  /**
   * The user was in the queue for too long.
   */
  QUEUE_TIMEOUT,

  /**
   * The bigscreen peer disconnected
   */
  BS_DISCONNECTED,

  /**
   * Trial ended
   */
  END_TRIAL,
}
