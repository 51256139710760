import { render, staticRenderFns } from "./UpdateModal.vue?vue&type=template&id=b71ed6a4&scoped=true&"
import script from "./UpdateModal.vue?vue&type=script&lang=ts&"
export * from "./UpdateModal.vue?vue&type=script&lang=ts&"
import style0 from "./UpdateModal.vue?vue&type=style&index=0&id=b71ed6a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b71ed6a4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VOverlay})
