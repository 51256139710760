import { defineModule } from "direct-vuex";

import { moduleActionContext, axiosInstance as axios } from "@/store/";
import router from "@/router";

import * as StatsManager from "@/../common/stats/StatsManager";

interface CreateSessionResponse {
  payUrl: string;
}

type PaymentStatus = "CLOSED" | "REJECTED" | "EXPIRED";

const module = defineModule({
  namespaced: true as const,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async setup(context): Promise<void> {
      const { rootDispatch } = moduleActionContext(context, module);

      if (router.currentRoute.query["tbId"]) {
        const status: PaymentStatus = router.currentRoute.query["status"] as PaymentStatus;
        if (status === "CLOSED") {
          rootDispatch.payment.onPurchaseCompleted();
          // Otherwise the payment failed or the user closed the popup without completing the payment
        } else if (status === "REJECTED" || status === "EXPIRED") rootDispatch.payment.onPurchaseFailed();
        // Remove the query params
        router.replace({
          query: {
            ...router.currentRoute.query,
            tbId: undefined,
            status: undefined,
            supplierRefId: undefined,
            type: undefined,
          },
        });
      }
    },
    async purchaseSubscription(context): Promise<void> {
      const { rootState } = moduleActionContext(context, module);
      try {
        const res = await axios.post<CreateSessionResponse>("/transactbridge/createSession", {
          currentUrl: window.location.href,
          locale: rootState.language.language || "en",
        });

        if (res.data.payUrl) {
          StatsManager.SendNavigationStats(window.location.href, "TRANSACTBRIDGE_CHECKOUT");
          const tab = window.open(res.data.payUrl, "_blank");
          if (tab) tab.focus();
          // If the tab is blocked by the browser, we redirect the user to the payment page
          else window.location.href = res.data.payUrl;
        } else console.error("No payUrl in response");
      } catch (error) {
        console.error(error);
      }
    },
  },
});

export default module;
