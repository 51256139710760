
































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import * as StatsManager from "@/../common/stats/StatsManager";

@Component
export default class AccountSuspendedPopup extends Vue {
  @Prop(Boolean) show: boolean;
  disabled = false;

  openAuthPopup(): void {
    this.disabled = true;
    this.$store.direct.dispatch.authentication.openAuthPopup({
      onClose: () => {
        this.disabled = false;
        if (this.$store.direct.getters.authentication.isAuthenticated) this.$emit("close");
      },
    });
    StatsManager.SendButtonClickStats("NEED_PARTNER_AUTHENTICATION_POPUP", "open");
  }
}
