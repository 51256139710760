import { defineModule } from "direct-vuex";

import { LocaleMessageObject } from "vue-i18n";

import { moduleActionContext, axiosInstance as axios } from "@/store/";
import VueI18n from "@/plugins/i18n";
import { AxiosResponse } from "axios";

interface TagLocalizedClientInfo {
  name: string;
}

type Locale = "fr" | "en" | string;

export class Tag {
  _id: string;
  name: string;
  enabled: boolean;
  icon: string;
  localizedClientInfo: { [key in Locale]: TagLocalizedClientInfo };
  urlSegment?: string;
}

let tagsPromise: Promise<AxiosResponse<Tag[]>> | undefined = undefined;

export interface TagState {
  tags: Tag[];
}

const module = defineModule({
  namespaced: true as const,
  state: {
    tags: [],
  } as TagState,
  mutations: {
    SET_TAGS(state, tags: Tag[]) {
      state.tags = tags;
    },
  },
  actions: {
    async getTags(context): Promise<Tag[]> {
      const { rootGetters, state, commit } = moduleActionContext(context, module);
      try {
        if (state.tags.length > 0) return state.tags;
        if (!tagsPromise) tagsPromise = axios.get<Tag[]>(`/api/v1/tags`);
        const { data } = await tagsPromise;
        commit.SET_TAGS(data);
        const locales: Locale[] = rootGetters.language.availableLanguageCodes;
        locales.forEach((locale) => {
          const messages: LocaleMessageObject = {};
          state.tags.forEach((a) => (messages[`tags.${a._id}.name`] = a?.localizedClientInfo?.[locale]?.name));
          VueI18n.mergeLocaleMessage(locale, messages);
        });
        return state.tags;
      } catch (error) {
        console.error(error);
        commit.SET_TAGS([]);
        return state.tags;
      }
    },
  },
});

export default module;
