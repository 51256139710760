export enum TrialEndReason {
  /**
   * The trial ended because the user won the trial level(s).
   */
  WIN,

  /**
   * The trial ended because the user lost the trial level(s).
   */
  LOSE,

  /**
   * The trial ended because the user finished the trial level(s).
   */
  ENDED,

  /**
   * The trial ended because there is no time remaining.
   */
  TIMEOUT,
}
