













































import { Bonus, Game } from "@/store/modules/games";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BonusCard extends Vue {
  @Prop({ type: Object, required: true }) bonus: { bonus: Bonus, index: number };
  @Prop({ type: Object, required: true }) game: Game;
}
