import { defineModule } from "direct-vuex";
import { UAParser } from "ua-parser-js";
import mergeWith from "lodash/mergeWith";
import isArray from "lodash/isArray";

import { moduleActionContext, moduleGetterContext, axiosInstance as axios } from "@/store";
import router from "@/router";
import i18n from "@/plugins/i18n";

import { isValidHttpUrl } from "@/../common/utils/utils";
import * as ShareUtils from "@/../common/share";
import * as StatsManager from "@/../common/stats/StatsManager";

import { Manifest } from "@/interfaces/manifest";
import { Game } from "./games";

export enum OrientationMode {
  PORTRAIT,
  LANDSCAPE,
}

export enum SERVICE_WORKER_OUTGOING_MESSAGE_TYPE {
  UPDATE_MANIFEST = "UPDATE_MANIFEST",
  UPDATE_ENDPOINT = "UPDATE_ENDPOINT",
  UPDATE_STATS_INFO = "UPDATE_STATS_INFO",
}

export async function SendMessageToServiceWorker(
  type: SERVICE_WORKER_OUTGOING_MESSAGE_TYPE,
  data: unknown
): Promise<void> {
  await navigator?.serviceWorker?.ready;
  if (navigator?.serviceWorker?.controller)
    navigator.serviceWorker.controller.postMessage({
      type,
      data,
    });
}

const parser = new UAParser();

interface Add2HomeAndroidIntercept {
  show: boolean;
  onSkip?: () => void;
  onClose?: () => void;
}

export interface MobileState {
  orientation: OrientationMode;
  manifest: Manifest | undefined;
  pushNotifEnabled: boolean;
  defferedPWAPrompt: BeforeInstallPromptEvent | undefined;
  isBigScreenResolution: boolean;
  lastModified: Date | undefined; // Date Last-Modified from the server
  showUpdateModal: boolean; // Flag to show the refresh modal
  add2HomeAndroidIntercept: Add2HomeAndroidIntercept;
  showAgreementPopup: boolean;
  showSubscribeTutoPopup: boolean;
  showRenewTutoPopup: boolean;
  showSharePopup: boolean;
  showSubscribeBannerPopup: boolean;
  showLoginPopup: boolean;
  loginPopupCloseToWelcome: boolean;
  showRegisterPopup: boolean;
  showTvCodePopup: boolean;
}

const LOCAL_STORAGE_COUNT_PWA_LAUNCHES = "pwaLaunches";

const module = defineModule({
  namespaced: true as const,
  state: {
    orientation: OrientationMode.PORTRAIT,
    manifest: undefined,
    pushNotifEnabled: false,
    defferedPWAPrompt: undefined,
    isBigScreenResolution: true,
    lastModified: undefined,
    showUpdateModal: false,
    add2HomeAndroidIntercept: {
      show: false,
    },
    showAgreementPopup: false,
    showSubscribeTutoPopup: false,
    showRenewTutoPopup: false,
    showSharePopup: false,
    showSubscribeBannerPopup: false,
    showLoginPopup: false,
    loginPopupCloseToWelcome: false,
    showRegisterPopup: false,
    showTvCodePopup: false,
  } as MobileState,
  getters: {
    isWebApp: (): boolean =>
      window.navigator.standalone == true ||
      window.matchMedia("(display-mode: standalone)").matches ||
      window.matchMedia("(display-mode: fullscreen)").matches,
    isiOS: (): boolean => parser.getOS().name === "iOS",
    isAndroid: (): boolean => parser.getOS().name === "Android",
    isChrome: (): boolean => parser.getBrowser().name === "Chrome",
    isSafari: (): boolean => parser.getBrowser().name === "Mobile Safari",
    isBrowserValid: (): boolean => {
      if (/^true$/i.test(process.env.VUE_APP_SKIP_BROWSER_CHECK)) return true;
      if (parser.getOS().name === "Android") {
        switch (parser.getBrowser().name) {
          case "Chrome": // Also matches Vivaldi
          case "Samsung Browser":
          case "Opera":
          case "Edge":
          case "Facebook":
          case "Chrome WebView":
            return true;
          default:
            return false;
        }
      }
      if (parser.getOS().name === "iOS") {
        switch (parser.getBrowser().name) {
          case "Mobile Safari":
            return true;
          default:
            return false;
        }
      }
      return false;
    },
    getBrowser: (): string | undefined => {
      return parser.getBrowser().name;
    },
    getOS: (): string | undefined => {
      return parser.getOS().name;
    },
    shareOptions: (...args) => (game?: Game): ShareUtils.ShareOptions => {
      const { rootState } = mod1GetterContext(args);

      let url = process.env.VUE_APP_SHARE_URL;

      if (!isValidHttpUrl(url) && router.currentRoute.name === "Home") {
        url = `/`;
      }

      let title = i18n.t("share.app.title").toString();
      let text = i18n.t("share.app.text").toString();
      if (game) {
        const gameName = i18n.t(`games.${game._id}.name`).toString() || "";
        title = gameName;
        text = i18n.t("share.game.text", { game: gameName }).toString();
      }

      return {
        title,
        text,
        referralCode: rootState.authentication.user?.referralCode,
        url,
      };
    },
    isBigScreenResolution: (state): boolean => {
      return state.isBigScreenResolution;
    },
    canShare: (...args): boolean => {
      const { getters } = mod1GetterContext(args);

      return ShareUtils.CanShare(getters.shareOptions());
    },
    canDeferPWAPrompt: (state): boolean => state.defferedPWAPrompt !== undefined,
    isAbleNotification: (): boolean => window.Notification !== undefined,
    gaveAgreement: (...args): boolean => {
      const { rootState } = mod1GetterContext(args);
      return rootState.authentication.user?.gaveAgreement ?? true;
    },
    countPWALaunches: (): number => {
      const count = localStorage.getItem(LOCAL_STORAGE_COUNT_PWA_LAUNCHES);
      if (count) return parseInt(count);
      return 0;
    },
  },
  mutations: {
    SET_BIGSCREEN_RESOLUTION(state, value: boolean) {
      state.isBigScreenResolution = value;
    },
    SET_ORIENTATION(state) {
      state.orientation = window.innerHeight > window.innerWidth ? OrientationMode.PORTRAIT : OrientationMode.LANDSCAPE;
    },
    SET_MANIFEST(state, manifest: Manifest) {
      state.manifest = mergeWith(state.manifest, manifest, (objValue, srcValue) =>
        isArray(objValue) ? srcValue : undefined
      );
      // Set the custom manifest, for iOS
      if (parser.getOS().name === "iOS") {
        // Prepare the custom manifest as blob (raw-data)
        const stringManifest = JSON.stringify(state.manifest);
        const blob = new Blob([stringManifest], { type: "application/json" });
        const manifestURL = URL.createObjectURL(blob);
        // Create the manifest link element if it doesn't exist
        if (!document.head.querySelector("link[rel='manifest']")) {
          const customManifest = document.createElement("link");
          customManifest.setAttribute("rel", "manifest");
          customManifest.setAttribute("id", "custom-manifest");
          document.head.appendChild(customManifest);
        }
        // Set the custom manifest data
        document.querySelector("#custom-manifest")?.setAttribute("href", manifestURL);
      }
      // Set the manifest used by the service worker, for Android
      else {
        // Wait for the service worker to be ready
        navigator?.serviceWorker?.ready.then(() => {
          // Send the custom manifest data to the service worker
          SendMessageToServiceWorker(SERVICE_WORKER_OUTGOING_MESSAGE_TYPE.UPDATE_MANIFEST, manifest);
          // Create the manifest link element if it doesn't exist
          if (!document.head.querySelector("link[rel='manifest']")) {
            const customManifest = document.createElement("link");
            customManifest.setAttribute("rel", "manifest");
            customManifest.setAttribute("href", "manifest.webmanifest");
            document.head.appendChild(customManifest);
          }
        });
      }
    },
    SET_PUSH_NOTIF_ENABLED(state, enabled: boolean) {
      state.pushNotifEnabled = enabled;
    },
    SET_DEFERRED_PWA_PROMPT(state, event: BeforeInstallPromptEvent) {
      state.defferedPWAPrompt = event;
    },
    SHOW_DEFERRED_PWA_PROMPT(state) {
      state.defferedPWAPrompt?.prompt();
      state.defferedPWAPrompt = undefined;
    },
    SET_LAST_MODIFIED(state, lastModified: Date) {
      state.lastModified = lastModified;
    },
    SET_SHOW_UPDATE_MODAL(state, show: boolean) {
      state.showUpdateModal = show;
      StatsManager.SendNavigationStats(location.href, "UPDATE_POPUP");
    },
    SHOW_ADD2HOME_ANDROID_INTERCEPT(state, { onSkip, onClose }: { onSkip?: () => void; onClose?: () => void }) {
      state.add2HomeAndroidIntercept.onSkip = onSkip;
      state.add2HomeAndroidIntercept.onClose = onClose;
      state.add2HomeAndroidIntercept.show = true;
      document.querySelector("html")?.classList.add("noscroll");
      window.scrollTo(0, 0);
      StatsManager.SendNavigationStats(location.href, "ADD2HOME_ANDROID_INTERCEPT");
    },
    CLOSE_SHOW_ADD2HOME_ANDROID_INTERCEPT(state) {
      if (state.add2HomeAndroidIntercept.show) {
        state.add2HomeAndroidIntercept.onClose?.();
        state.add2HomeAndroidIntercept.show = false;
        document.querySelector("html")?.classList.remove("noscroll");
        StatsManager.SendNavigationStats("ADD2HOME_ANDROID_INTERCEPT", location.href);
      }
    },
    SHOW_AGREEMENT_POPUP(state) {
      state.showAgreementPopup = true;
      document.querySelector("html")?.classList.add("noscroll");
      window.scrollTo(0, 0);
      StatsManager.SendNavigationStats(window.location.href, "AGREEMENT_POPUP");
    },
    CLOSE_AGREEMENT_POPUP(state) {
      state.showAgreementPopup = false;
      document.querySelector("html")?.classList.remove("noscroll");
      StatsManager.SendNavigationStats("AGREEMENT_POPUP", window.location.href);
    },
    SHOW_RENEW_TUTO_POPUP(state) {
      state.showRenewTutoPopup = true;
      document.querySelector("html")?.classList.add("noscroll");
      window.scrollTo(0, 0);
      StatsManager.SendNavigationStats(window.location.href, "RENEW_TUTO_POPUP");
    },
    CLOSE_RENEW_TUTO_POPUP(state) {
      state.showRenewTutoPopup = false;
      document.querySelector("html")?.classList.remove("noscroll");
      StatsManager.SendNavigationStats("RENEW_TUTO_POPUP", window.location.href);
    },
    SHOW_TV_CODE_POPUP(state) {
      state.showTvCodePopup = true;
      document.querySelector("html")?.classList.add("noscroll");
      window.scrollTo(0, 0);
      StatsManager.SendNavigationStats(window.location.href, "TV_CODE_POPUP");
    },
    CLOSE_TV_CODE_POPUP(state) {
      state.showTvCodePopup = false;
      document.querySelector("html")?.classList.remove("noscroll");
      StatsManager.SendNavigationStats("TV_CODE_POPUP", window.location.href);
    },
    SHOW_SUBSCRIBE_TUTO_POPUP(state) {
      state.showSubscribeTutoPopup = true;
      document.querySelector("html")?.classList.add("noscroll");
      window.scrollTo(0, 0);
      StatsManager.SendNavigationStats(window.location.href, "SUBSCRIBE_TUTO_POPUP");
    },
    CLOSE_SUBSCRIBE_TUTO_POPUP(state) {
      state.showSubscribeTutoPopup = false;
      document.querySelector("html")?.classList.remove("noscroll");
      StatsManager.SendNavigationStats("SUBSCRIBE_TUTO_POPUP", window.location.href);
    },
    SHOW_SHARE_POPUP(state) {
      state.showSharePopup = true;
      document.querySelector("html")?.classList.add("noscroll");
      window.scrollTo(0, 0);
      StatsManager.SendNavigationStats(window.location.href, "SHARE_POPUP");
    },
    CLOSE_SHARE_POPUP(state) {
      state.showSharePopup = false;
      document.querySelector("html")?.classList.remove("noscroll");
      StatsManager.SendNavigationStats("SHARE_POPUP", window.location.href);
    },
    INCREMENT_PWA_LAUNCHES() {
      const count = localStorage.getItem(LOCAL_STORAGE_COUNT_PWA_LAUNCHES);
      if (count) localStorage.setItem(LOCAL_STORAGE_COUNT_PWA_LAUNCHES, (parseInt(count) + 1).toString());
      else localStorage.setItem(LOCAL_STORAGE_COUNT_PWA_LAUNCHES, "1");
    },
    SHOW_SUBSCRIBE_BANNER_POPUP(state) {
      state.showSubscribeBannerPopup = true;
      document.querySelector("html")?.classList.add("noscroll");
      window.scrollTo(0, 0);
      StatsManager.SendNavigationStats(window.location.href, "SUBSCRIBE_BANNER_POPUP");
    },
    CLOSE_SUBSCRIBE_BANNER_POPUP(state) {
      state.showSubscribeBannerPopup = false;
      document.querySelector("html")?.classList.remove("noscroll");
      StatsManager.SendNavigationStats("SUBSCRIBE_BANNER_POPUP", window.location.href);
    },
    SHOW_LOGIN_POPUP(state, closeToWelcome: boolean) {
      state.showLoginPopup = true;
      state.loginPopupCloseToWelcome = closeToWelcome;
      document.querySelector("html")?.classList.add("noscroll");
      window.scrollTo(0, 0);
      StatsManager.SendNavigationStats(window.location.href, "LOGIN_POPUP");
    },
    CLOSE_LOGIN_POPUP(state) {
      state.showLoginPopup = false;
      document.querySelector("html")?.classList.remove("noscroll");
      StatsManager.SendNavigationStats("LOGIN_POPUP", window.location.href);
    },
    SHOW_REGISTER_POPUP(state) {
      state.showRegisterPopup = true;
      document.querySelector("html")?.classList.add("noscroll");
      window.scrollTo(0, 0);
      StatsManager.SendNavigationStats(window.location.href, "REGISTER_POPUP");
    },
    CLOSE_REGISTER_POPUP(state) {
      state.showRegisterPopup = false;
      document.querySelector("html")?.classList.remove("noscroll");
      StatsManager.SendNavigationStats("REGISTER_POPUP", window.location.href);
    },
  },
  actions: {
    async init(context) {
      const { commit, dispatch, getters } = moduleActionContext(context, module);
      commit.SET_ORIENTATION();
      window.addEventListener("resize", () => commit.SET_ORIENTATION());
      dispatch.updateAndSetManifest();

      if (getters.isAbleNotification) {
        if (Notification?.permission === "denied") commit.SET_PUSH_NOTIF_ENABLED(false);
        else {
          const serviceWorker = await navigator.serviceWorker.ready;
          const sub = await serviceWorker.pushManager.getSubscription();
          if (sub) commit.SET_PUSH_NOTIF_ENABLED(true);
        }
      }

      SendMessageToServiceWorker(
        SERVICE_WORKER_OUTGOING_MESSAGE_TYPE.UPDATE_ENDPOINT,
        process.env.VUE_APP_API_ENDPOINT
      );
    },
    updateAndSetManifest(context) {
      const { commit, rootGetters } = moduleActionContext(context, module);

      const dynamicManifest: Manifest = {
        name: process.env.VUE_APP_PWA_NAME || `CareGame`,
        short_name: process.env.VUE_APP_PWA_NAME || "CareGame",
        description: "Mobile Gaming, One Click Away",
        display: "fullscreen",
        start_url: `${location.origin}/`,
        orientation: "any",
        background_color: "#07071B",
        theme_color: "#00AF77",
        icons: [
          {
            src: "/pwa/android-chrome-maskable-192x192.png",
            sizes: "192x192",
            type: "image/png",
            purpose: "maskable any",
          },
          {
            src: "/pwa/android-chrome-maskable-512x512.png",
            sizes: "512x512",
            type: "image/png",
            purpose: "maskable any",
          },
          {
            src: "/pwa/android-chrome-192x192.png",
            sizes: "192x192",
            type: "image/png",
            purpose: "any",
          },
          {
            src: "/pwa/android-chrome-512x512.png",
            sizes: "512x512",
            type: "image/png",
            purpose: "any",
          },
        ],
        related_applications: [
          {
            platform: "webapp",
            url: `/manifest.webmanifest`,
          },
        ],
      };
      if (rootGetters.authentication.jwt) dynamicManifest.start_url += rootGetters.authentication.jwt;
      commit.SET_MANIFEST(dynamicManifest);
    },
    async setBigScreenResolution(context, value: boolean): Promise<boolean> {
      const { commit } = moduleActionContext(context, module);

      const body = {
        bigscreen_resolution: value ? "BIGSCREEN" : "MOBILE",
      };
      const response = await axios.post(`/api/v1/userPrefs`, body);
      if (response.status === 200) {
        commit.SET_BIGSCREEN_RESOLUTION(value);
        return value;
      }
      return !value;
    },
    async fetchBigScreenResolution(context): Promise<boolean> {
      const { commit } = moduleActionContext(context, module);
      const response = await axios.get(`/api/v1/userPrefs/bigscreen_resolution`);
      if (response.status === 200) {
        if (response.data === "MOBILE") {
          commit.SET_BIGSCREEN_RESOLUTION(false);
          return false;
        }
        if (response.data === "BIGSCREEN") {
          commit.SET_BIGSCREEN_RESOLUTION(true);
          return true;
        }
      }
      return false;
    },
    async createNotificationSubscription(context): Promise<PushSubscription> {
      const { commit } = moduleActionContext(context, module);
      //wait for service worker installation to be ready, and then

      const serviceWorker = await navigator.serviceWorker.ready;
      // subscribe and return the subscription
      const subscription = await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: process.env.VUE_APP_PUSH_VAPID_PUBLIC_KEY,
      });

      await axios.post(`/api/v1/pushsubscribtion/subscribe`, subscription);
      commit.SET_PUSH_NOTIF_ENABLED(true);
      return subscription;
    },
    async removeNotificationSubscription(context): Promise<void> {
      const { commit } = moduleActionContext(context, module);
      const serviceWorker = await navigator.serviceWorker.ready;
      const sub = await serviceWorker.pushManager.getSubscription();
      sub?.unsubscribe();
      await axios.delete(`/api/v1/pushsubscribtion/subscribe`);
      commit.SET_PUSH_NOTIF_ENABLED(false);
    },
    async share(context, { game }: { game?: Game } = { game: undefined }): Promise<void> {
      const { getters } = moduleActionContext(context, module);

      return ShareUtils.Share(getters.shareOptions(game));
    },
    /**
     * Check if the PWA has changed since the last reload
     */
    async checkPWAChanged(context) {
      const { commit } = moduleActionContext(context, module);
      try {
        // Add a random query string to the URL to avoid caching.
        const url = new URL(location.origin);
        // Use both random and Date.now()
        const random = Math.round(Date.now() * Math.random())
          .toString(10)
          .substring(2, 15);
        url.searchParams.set("c", random);

        // Fetch the Last-Modified date from the server
        const response = await fetch(url.href, {
          method: "HEAD",
          cache: "no-cache",
        });
        const lastModified = response.headers.get("Last-Modified") || "";
        // const lastModified = "Thu, 21 Sep 3028 15:35:46 GMT";

        // Convert the Last-Modified string to a Date object
        const lastModifiedDate = new Date(lastModified);

        // If there is no Last-Modified date or if it is invalid, return
        if (!lastModified || isNaN(lastModifiedDate.getTime())) return;
        // Store the new Last-Modified date
        commit.SET_LAST_MODIFIED(lastModifiedDate);

        // Get the last PWA reload date from the local storage
        const lastPwaReload = localStorage.getItem("lastPwaReload") || "";
        // Convert the last PWA reload string to a Date object
        let lastPwaReloadDate = new Date(parseInt(lastPwaReload));

        // If there is no last PWA reload date, store the new Last-Modified date as the last PWA reload date
        // This is to avoid showing the refresh popup on the first load of the PWA
        if (!lastPwaReload || isNaN(lastPwaReloadDate.getTime())) {
          lastPwaReloadDate = lastModifiedDate;
          localStorage.setItem("lastPwaReload", lastPwaReloadDate.getTime().toString());
        }

        // Show the refresh popup if needed if the new Last-Modified date is more recent than the last PWA reload date
        if (lastModifiedDate > lastPwaReloadDate) {
          commit.SET_SHOW_UPDATE_MODAL(true);
        }
      } catch (error) {
        console.error("Error fetching Last-Modified date:", error);
      }
    },
    /**
     * Reload the PWA to apply updates
     */
    async reloadPWA(context) {
      const { state } = moduleActionContext(context, module);

      // Store the new Last-Modified date as the last PWA reload date
      // We don't store the current date as the last PWA reload date in case the Last-Modified date is in the future
      if (state.lastModified) localStorage.setItem("lastPwaReload", state.lastModified.getTime().toString());
      // Reload the PWA to apply updates
      window.location.reload();
    },
  },
});

export default module;
const mod1GetterContext = (args: [any, any, any, any]) => moduleGetterContext(args, module);
