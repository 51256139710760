














































































import { Component, Prop } from "vue-property-decorator";
import CardGame from "./CardGame.vue";
import CardL from "./CardL.vue";

import * as StatsManager from "@/../common/stats/StatsManager";

@Component({
  components: {
    CardL,
  },
})
export default class CardGameL extends CardGame {
  @Prop({ type: Boolean, default: false }) showPlayButton: boolean;
  @Prop({ type: Boolean, default: false }) showFavoriteButton: boolean;

  get favorite(): boolean {
    if (!this.game) return false;
    return this.$store.direct.state.games.favoriteGamesIds.includes(this.game._id);
  }

  async toggleFavorite(): Promise<void> {
    
    if (!this.game) return;
    if (this.favorite) await this.$store.direct.dispatch.games.removeFromFavorite(this.game);
    else await this.$store.direct.dispatch.games.addToFavorite(this.game);
    StatsManager.SendButtonClickStats(location.href, "favorite", { newValue: !this.favorite });
  }

  clickPlayButton(): void {
    StatsManager.SendButtonClickStats(location.href, "play", {
      game: {
        id: this.game._id,
        name: this.game.name,
        appID: this.game.appID,
      },
    });

    if (!this.$store.direct.getters.mobile.isBrowserValid) {
      this.$store.direct.commit.SHOW_ALERT({
        text: this.$t("alert.alerts.bad-browser.text").toString(),
        title: this.$t("alert.alerts.bad-browser.title").toString(),
      });
      StatsManager.SendNavigationStats(location.href, "INVALID_BROWSER_ALERT");
      return;
    }

    this.$store.direct.dispatch.games.play({ game: this.game, playOnBigScreen: false });
  }
}
