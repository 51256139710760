











































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import ListedGame from "@/components/ListedGame.vue";
import TapBar from "@/components/TapBar.vue";

import { Game, SubscriptionAccess } from "@/store/modules/games";
import HomeScroller from "@/components/HomeScroller.vue";
import CardGameL from "@/components/CardGameL.vue";
import GoUltimateButton from "@/components/Common/GoUltimateButton.vue";

enum ListType {
  GENRE,
  ALL_GAMES,
}

interface Tab {
  name: string;
  urlSegment: string;
  type: ListType;
  imageLink: string;
  colorGradient1?: string;
  colorGradient2?: string;
}

@Component({
  components: {
    ListedGame,
    TapBar,
    HomeScroller,
    CardGameL,
    GoUltimateButton,
  },
})
export default class MyGames extends Vue {
  loaded = false;

  get games(): Game[] {
    return this.$store.direct.state.games.favoriteGames;
  }

  get lastPlayedGames(): Game[] {
    return this.$store.direct.state.games.lastPlayedGames.slice(0, 10);
  }

  get subscribed(): boolean {
    const sub = this.$store.direct.getters.games.getHighestSubscription;
    return !sub || sub.access === SubscriptionAccess.FULL;
  }


  get backgroundColor(): string {
    return `radial-gradient(74.53% 76.79% at 10% 10%, #3C2920, #3A1B19, #07071b 100%)`;
  }

  async init(): Promise<void> {
    await this.$store.direct.dispatch.games.getFavoriteGames();
    await this.$store.direct.dispatch.games.getLastPlayedGames();
    this.loaded = true;
  }

  async created(): Promise<void> {
    await this.init();
  }

  async activated(): Promise<void> {
    await this.init();
  }
}
