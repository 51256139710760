























































































import { Component, Prop } from "vue-property-decorator";

import CardGame from "../../CardGame.vue";

@Component
export default class CardGameXL extends CardGame {
  @Prop(String) readonly subTitle: string;

  get description(): string {
    return this.subTitle ?? (this.$t(`games.${this.game._id}.shortDescription`) as string);
  }

  @Prop({ type: Boolean, default: false }) showPlayButton: boolean;

  clickPlayButton(): void {
    if (!this.$store.direct.getters.mobile.isBrowserValid) {
      this.$store.direct.commit.SHOW_ALERT({
        text: this.$t("alert.alerts.bad-browser.text").toString(),
        title: this.$t("alert.alerts.bad-browser.title").toString(),
      });
      return;
    }
    this.$store.direct.dispatch.games.play({ game: this.game, playOnBigScreen: false });
  }
}
