

































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { HomeSectionListedCategory } from "@/store/modules/homesections";

@Component({
  components: {},
})
export default class CardCategory extends Vue {
  @Prop(Object) readonly data: HomeSectionListedCategory;

  get to(): string {
    return this.data.link ?? "";
  }

  get backgroundColor(): string {
    return `radial-gradient(
              49.03% 62.83% at 68.14% 68.02%,
              ${this.data.colorGradient1} 15.07%,
              ${this.data.colorGradient2} 59.18%,
              ${this.data.colorGradient3} 100%
            ),
          linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(14, 15, 24, 0.78) 73.01%)`;
  }
}
