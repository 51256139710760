

















































































































































































































































































































































































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

import { Game, Screenshot, SubscriptionAccess } from "@/store/modules/games";

import CardScreenshot from "@/components/CardScreenshot.vue";
import CardGameL from "@/components/CardGameL.vue";
import HomeScroller from "@/components/HomeScroller.vue";
import TapBar from "@/components/TapBar.vue";
import { Genre } from "@/store/modules/genres";
import CardGameVideo from "@/components/Home/HomeSections/CardGameVideo.vue";

import * as StatsManager from "@/../common/stats/StatsManager";
import PlayButton from "@/components/Common/PlayButton.vue";
import FavButton from "@/components/GameDesc/FavButton.vue";
import ShareButton from "@/components/GameDesc/ShareButton.vue";
import BonusScroller from "@/components/GameDesc/Bonuses/BonusScroller.vue";
import GameTags from "@/components/GameDesc/GameTags.vue";
import EnterCodePopup from "@/components/Bigscreen/EnterCodePopup.vue";
import { UserPermission } from "@/store/modules/authentication";

@Component({
  components: {
    CardScreenshot,
    CardGameL,
    CoolLightBox,
    HomeScroller,
    TapBar,
    CardGameVideo,
    PlayButton,
    FavButton,
    ShareButton,
    BonusScroller,
    GameTags,
    EnterCodePopup,
  },
})
export default class GameView extends Vue {
  currentSShot: number | null = null;
  fullDescription = false;
  accessUpdateInterval: number | null = null;

  get info() {
    return [
      {
        key: this.$t("game.sections.info.publisher"),
        value: this.publisher,
      },
    ];
  }

  get screenshots(): Screenshot[] {
    return (
      this.game?.screenshots
        ?.filter((s) => s)
        .map((s) => ({
          ...s,
          thumbnail: s.thumbnail || s.url,
          autoplay: true,
        })) || []
    );
  }

  get favorite(): boolean {
    if (!this.game) return false;
    return this.$store.direct.state.games.favoriteGamesIds.includes(this.game._id);
  }

  get isPortrait(): boolean {
    return this.game?.orientation === "PORTRAIT";
  }

  get gameSubscription(): SubscriptionAccess | undefined {
    if (!this.game) return undefined;
    return this.$store.direct.getters.games.getGameSubscription(this.game) || SubscriptionAccess.VISIBLE;
  }

  async play(e: Event, playOnBigScreen = false): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    if (!this.game) return;

    StatsManager.SendButtonClickStats(location.href, "play", {
      game: {
        name: this.game.name,
        appID: this.game.appID,
        isComingSoon: this.game.isComingSoon,
      },
      gameSubscription: this.gameSubscription,
    });

    if (this.game?.isComingSoon) return;
    if (!this.$store.direct.getters.mobile.isBrowserValid) {
      this.$store.direct.commit.SHOW_ALERT({
        text: this.$t("alert.alerts.bad-browser.text").toString(),
        title: this.$t("alert.alerts.bad-browser.title").toString(),
      });
      StatsManager.SendNavigationStats(location.href, "INVALID_BROWSER_ALERT");
      return;
    }

    this.$store.direct.dispatch.games.play({ game: this.game, playOnBigScreen: playOnBigScreen });
  }

  async playOnTv(e: Event): Promise<void> {
    await this.$store.direct.dispatch.bigscreen.isPairedToBigScreen();
    if (!this.$store.direct.getters.bigscreen.isBigscreenPaired) {
      e.preventDefault();
      e.stopPropagation();
      this.$store.direct.commit.mobile.SHOW_TV_CODE_POPUP();
    } else {
      this.play(e, true);
      this.$store.direct.commit.mobile.CLOSE_TV_CODE_POPUP();
    }
  }

  async toggleFavorite(): Promise<void> {
    if (!this.game) return;
    if (this.favorite) await this.$store.direct.dispatch.games.removeFromFavorite(this.game);
    else await this.$store.direct.dispatch.games.addToFavorite(this.game);
    StatsManager.SendButtonClickStats(location.href, "favorite", { newValue: !this.favorite });
  }

  get pegiRating(): number {
    return this.game?.contentRating?.pegi?.rating || 3;
  }

  get game(): Game | undefined {
    return this.$store.direct.getters.games.getGame(this.$route.params.game);
  }

  get similarGames(): Game[] {
    return (
      this.game?.similarGames
        ?.map((gameID) => this.$store.direct.state.games.games.find((game) => game._id === gameID) as Game)
        .filter((g) => g) || []
    );
  }

  get publisher(): string {
    return this.$store.direct.state.publishers.publishers.find((p) => p._id === this.game?.publisher)?.name || "";
  }

  get contentRating(): string {
    if (this.game?.contentRating.pegi.rating)
      return this.$t("game.contentRating.simple", { age: this.game?.contentRating.pegi.rating }).toString();
    return "";
  }

  get canShare(): boolean {
    return this.$store.direct.getters.mobile.canShare;
  }

  get hasBigScreenPermission(): boolean {
    return this.$store.direct.getters.authentication.hasPermission(UserPermission.BIGSCREEN);
  }

  get subscribed(): boolean {
    const sub = this.$store.direct.getters.games.getHighestSubscription;
    return !sub || sub.access === SubscriptionAccess.FULL;
  }

  playButtonText(): string {
    if (this.game?.isComingSoon) return this.$t(`game.buttons.play.coming-soon`).toString();
    if (!this.gameSubscription) return "";
    return this.$t(`game.buttons.play.${this.gameSubscription}`).toString();
  }

  get noActiveSub(): boolean {
    return this.gameSubscription === SubscriptionAccess.VISIBLE;
  }

  async share(): Promise<void> {
    if (!this.game) return;
    this.$store.direct.dispatch.mobile.share({ game: this.game });
    StatsManager.SendButtonClickStats(location.href, "share");
  }

  async lightBoxOpened(): Promise<void> {
    const video = document.querySelector(".cool-lightbox__iframe video") as HTMLVideoElement;
    if (video) {
      // Add attributes to the video element to make it autoplay on iOS
      video.setAttribute("playsinline", "");
      video.setAttribute("muted", "");
      video.setAttribute("loop", "");

      // remove controls?
      // video.removeAttribute("controls");

      // play the video
      video.play();
    }
  }

  clickedScreenshot(screenshot: Screenshot, index: number): void {
    this.currentSShot = index;
    StatsManager.SendButtonClickStats(location.href, "screenshot", { index, screenshot: screenshot.url });
  }

  async created(): Promise<void> {
    await this.$store.direct.dispatch.games.getGameByAppID(this.$route.params.game);
    if (!this.game) return;
    this.$store.direct.dispatch.games.checkGameSubscription({ game: this.game });
    await this.$store.direct.dispatch.games.getGamesByIds(this.game.similarGames);
    await this.$store.direct.dispatch.tags.getTags();
    await this.$store.direct.dispatch.publishers.getPublishers();
    this.$store.direct.dispatch.homeSections.getHomeSections();
  }

  setupAccessUpdate(): void {
    if (this.accessUpdateInterval) return;
    this.accessUpdateInterval = setInterval(() => {
      if (this.game) this.$store.direct.dispatch.games.checkGameSubscription({ game: this.game, force: true });
    }, parseInt(process.env.VUE_APP_GAME_ACCESS_UPDATE_INTERVAL) || 1000 * 60);
  }

  activated(): void {
    if (this.game) this.$store.direct.dispatch.games.checkGameSubscription({ game: this.game, force: true });
    this.setupAccessUpdate();
  }

  deactivated(): void {
    if (this.accessUpdateInterval) {
      clearInterval(this.accessUpdateInterval);
      this.accessUpdateInterval = null;
    }
  }
}
