import { generateId } from "../utils/utils";

/**
 * Represents a User session for stats.
 */
export interface Session {
  /**
   * The ID of the session.
   */
  id: string;
  /**
   * The start time of the session.
   */
  start: Date;
  /**
   * The last active time of the session.
   *
   * This is updated every time the session is used.
   *
   * This is used to determine if the session is too idle and should expire.
   */
  lastActive: Date;
}

/**
 * Create a new session.
 * @returns The new session.
 */
function newSession(): Session {
  return {
    id: generateId(),
    start: new Date(),
    lastActive: new Date(),
  };
}

let _currentSession: Session | undefined = undefined;
let _cacheStorage: Cache | undefined = undefined;

/**
 * The key used to store the session in local storage or cache storage.
 */
const LOCAL_STORAGE_KEY = "cg_session";
/**
 * The maximum amount of time a session can be alive before it is considered expired.
 * In milliseconds.
 */
const SESSION_MAX_AGE = 1000 * 60 * 60 * 24 * 1; // 1 day
/**
 * The maximum amount of time a session can be idle before it is considered expired.
 * In milliseconds.
 */
const SESSION_MAX_IDLE = 1000 * 60 * 10; // 10 minutes

/**
 * Save the session to cache storage.
 * @param session The session to save.
 */
async function saveSessionToCacheStorage(session: Session): Promise<void> {
  if (!window.caches) return;
  if (!_cacheStorage) _cacheStorage = await caches.open("cg_session");

  const json = JSON.stringify(session);
  await _cacheStorage.put(LOCAL_STORAGE_KEY, new Response(json));
}

/**
 * Load the session from cache storage.
 * @returns The session, or undefined if no session exists.
 */
async function loadSessionFromCacheStorage(): Promise<Session | undefined> {
  if (!window.caches) return;
  if (!_cacheStorage) _cacheStorage = await caches.open("cg_session");

  const response = await _cacheStorage.match(LOCAL_STORAGE_KEY);
  if (!response) return undefined;

  const res = await response.json();
  res.start = new Date(res.start);
  res.lastActive = new Date(res.lastActive);
  return res;
}

/**
 * Clear the session from cache storage.
 */
async function clearSessionFromCacheStorage(): Promise<void> {
  if (!window.caches) return;
  if (!_cacheStorage) _cacheStorage = await caches.open("cg_session");

  await _cacheStorage.delete(LOCAL_STORAGE_KEY);
}

loadSessionFromCacheStorage().then((session) => {
  if (session) _currentSession = session;
});

/**
 * Save the session to browser storage.
 * @param session The session to save.
 */
function saveSession(session: Session): void {
  _currentSession = session;
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(session));
  saveSessionToCacheStorage(session);
}

/**
 * Load the session from browser storage.
 * @returns The session, or undefined if no session exists.
 */
function loadSession(): Session | undefined {
  if (_currentSession) return _currentSession;

  const item = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!item) return undefined;

  const res = JSON.parse(item);
  res.start = new Date(res.start);
  res.lastActive = new Date(res.lastActive);
  return res;
}

/**
 * Get the current session.
 *
 * A new session is created if:
 * * If no session exists.
 * * If the session is too old or too idle.
 *
 * Will update the last active time of the session.
 * @returns The current or new session.
 */
export function getSession(): Session {
  // Get session from browser storage
  let session = loadSession();

  // If no session, create a new one
  if (!session) {
    session = newSession();
    saveSession(session);
  } else {
    // Check if session is too old
    const age = Date.now() - session.start.getTime();
    if (age > SESSION_MAX_AGE) {
      session = newSession();
      saveSession(session);
    }

    // Check if session is too idle
    const idle = Date.now() - session.lastActive.getTime();
    if (idle > SESSION_MAX_IDLE) {
      session = newSession();
      saveSession(session);
    }
  }

  // Update last active time for the current session
  session.lastActive = new Date();
  saveSession(session);

  return session;
}

/**
 * Clear the session from browser storage.
 */
export function clearSession(): void {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  clearSessionFromCacheStorage();
}
