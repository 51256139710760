import { render, staticRenderFns } from "./Add2HomeTuto.vue?vue&type=template&id=61ad20b7&scoped=true&"
import script from "./Add2HomeTuto.vue?vue&type=script&lang=ts&"
export * from "./Add2HomeTuto.vue?vue&type=script&lang=ts&"
import style0 from "./Add2HomeTuto.vue?vue&type=style&index=0&id=61ad20b7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ad20b7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VIcon,VImg,VOverlay,VSkeletonLoader})
