







































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import * as StatsManager from "@/../common/stats/StatsManager";

@Component
export default class RenewTutoPopup extends Vue {
  close(): void {
    this.$store.direct.commit.mobile.CLOSE_RENEW_TUTO_POPUP();
    StatsManager.SendButtonClickStats("RENEW_TUTO_POPUP", "close");
  }

  subscribe(): void {
    this.$store.direct.commit.mobile.CLOSE_RENEW_TUTO_POPUP();
    StatsManager.SendButtonClickStats("RENEW_TUTO_POPUP", "subscribe");
    if (
      !process.env.VUE_APP_POPUP_HOW_TO_RENEW_REDIRECT_URL ||
      /^FALSE$/i.test(process.env.VUE_APP_POPUP_HOW_TO_RENEW_REDIRECT_URL)
    )
      return;
    const url = process.env.VUE_APP_POPUP_HOW_TO_RENEW_REDIRECT_URL;
    StatsManager.SendNavigationStats(location.href, url);
    location.replace(url);
  }
}
