























































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import i18n from "@/plugins/i18n";

@Component
export default class LoginForm extends Vue {
  @Prop({ type: Boolean, default: true }) showCloseButton!: boolean;
  @Prop({ type: Boolean, default: true }) withUsername!: boolean;

  form = {
    valid: false,
    working: false,
    username: "",
    login: "",
    password: "",
    showPassword: false,
    userNameAlreadyTakenError: "",
    loginAlreadyTakenError: "",
  };

  formRules = {
    required: (value: string) => !!value || i18n.t("profile.registration.register.form.errors.required"),
    min: (min: number) => (v: string) =>
      v.length >= min || i18n.t("profile.registration.register.form.errors.minCharacters", { n: min }),
    validationRegex: (value: string) =>
      /^[A-Za-zÀ-ÖØ-öø-ÿ0-9_-]*$/.test(value) || i18n.t("profile.registration.register.form.errors.format"),
  };

  $refs!: {
    form: HTMLFormElement;
  };

  timerId: number | null = null;

  intervalId: number | null = null;

  mounted(): void {
    this.intervalId = setInterval(() => {
      if (this.withUsername) this.checkUsername(this.form.username);
      this.checkLogin(this.form.login);
    }, 2000);
  }

  beforeDestroy(): void {
    if (this.intervalId) clearInterval(this.intervalId);
  }

  async checkUsernameDebounced(): Promise<void> {
    if (this.timerId) clearTimeout(this.timerId);
    this.timerId = setTimeout(() => this.checkUsername(this.form.username), 200);
  }

  async checkUsername(username: string): Promise<void> {
    if (!username) return;
    const valid = await this.$store.direct.dispatch.authentication.usernameValidity({ username });
    if (!valid)
      this.form.userNameAlreadyTakenError = this.$t(
        "profile.registration.register.form.errors.usernameTaken"
      ).toString();
    else this.form.userNameAlreadyTakenError = "";
  }

  async checkLoginDebounced(): Promise<void> {
    if (this.timerId) clearTimeout(this.timerId);
    this.timerId = setTimeout(() => this.checkLogin(this.form.login), 200);
  }

  async checkLogin(login: string): Promise<void> {
    if (!login) return;
    const valid = await this.$store.direct.dispatch.authentication.loginValidity({ login });
    if (!valid)
      this.form.loginAlreadyTakenError = this.$t("profile.registration.register.form.errors.loginTaken").toString();
    else this.form.loginAlreadyTakenError = "";
  }

  async register(): Promise<void> {
    this.$refs.form.validate();
    if (!this.form.valid) return;

    if (this.form.working) return;
    this.form.working = true;

    if (this.intervalId) clearInterval(this.intervalId);
    await Promise.all([
      new Promise((resolve) => setTimeout(resolve, 1000)),
      await this.$store.direct.dispatch.authentication.tempRegister({
        login: this.form.login,
        password: this.form.password,
        username: this.withUsername ? this.form.username : undefined,
      }),
    ]);
    this.$emit("registered");
    this.form.working = false;
  }
}
