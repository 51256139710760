

























































import Vue from "vue";
import { Component } from "vue-property-decorator";
import OGLTapBar from "@/components/OGLTapBar.vue";

@Component({
  components: {
    OGLTapBar,
  },
})
export default class Leaderboard extends Vue {}
