import { render, staticRenderFns } from "./bookmark_added_FILL1_wght400_GRAD0_opsz48.vue?vue&type=template&id=50377930&scoped=true&"
var script = {}
import style0 from "./bookmark_added_FILL1_wght400_GRAD0_opsz48.vue?vue&type=style&index=0&id=50377930&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50377930",
  null
  
)

export default component.exports