
































































































import { Component, Prop } from "vue-property-decorator";

import CardGame from "@/components/CardGame.vue";
import ListedGame from "@/components/ListedGame.vue";
import * as StatsManager from "@/../common/stats/StatsManager";

@Component({
  components: {
    ListedGame,
  },
})
export default class CardGameVideo extends CardGame {
  @Prop(Boolean) readonly disableLink: boolean;
  @Prop(Boolean) readonly showPlayButton: boolean;

  hidden = true;

  get videoURL(): string {
    return this.game.headerVideo;
  }

  deactivated() {
    this.$el.querySelector("video")?.pause();
  }

  activated(): void {
    // reload and play video after a page change
    //this.$el.querySelector("video")?.load();
    const video = this.$el.querySelector("video");
    if (video) {
      video.currentTime = 0;
      video.play();
    }
  }

  mounted() {
    const video = this.$el.querySelector("video");

    if (video && video.classList.contains("need-load")) {
      for (var source in video.children) {
        var videoSource = video.children[source];
        if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
          (videoSource as any).src = (videoSource as any).dataset.src;
        }
      }
      video.load();
      video.play();
      video.classList.remove("need-load");
    }

    const t = this.$el.querySelector("video");
    t?.addEventListener("canplay", (event) => {
      StatsManager.SendArbitraryStats("gamedesc-video-canplay", { appId: this.game.appID, videoUrl: this.game.headerVideo });
    });
    t?.addEventListener("canplaythrough", (event) => {
      StatsManager.SendArbitraryStats("gamedesc-video-canplaythrough", { appId: this.game.appID, videoUrl: this.game.headerVideo });
    });
  }
}
