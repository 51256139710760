import { defineModule } from "direct-vuex";

import { moduleActionContext, moduleGetterContext } from "@/store/";
import { Game } from "@/store/modules/games";

import * as StatsManager from "@/../common/stats/StatsManager";

enum PaymentProvider {
  FASTSPRING = "FASTSPRING",
  TRANSACTBRIDGE = "TRANSACTBRIDGE",
}

export interface PaymentState {
  showPayPopup: boolean;
  showPaymentCompletedPopup: boolean;
  showPaymentFailedPopup: boolean;
  lastRequestedGame: Game | undefined;
}

const module = defineModule({
  namespaced: true as const,
  state: {
    showPayPopup: false,
    showPaymentCompletedPopup: false,
    showPaymentFailedPopup: false,
    lastRequestedGame: undefined,
  } as PaymentState,
  getters: {
    enabled: (_state): boolean => /^true$/i.test(process.env.VUE_APP_CGPAYMENT_ENABLED),
    fallbackEnabled: (_state): boolean => /^true$/i.test(process.env.VUE_APP_CGPAYMENT_FALLBACK_ENABLED),
    skipRegister: (_state): boolean => /^true$/i.test(process.env.VUE_APP_CGPAYMENT_SKIP_REGISTER),
    shouldUseCGPayement: (...args): boolean => {
      const { rootState } = mod1GetterContext(args);
      if (!rootState.authentication.user) return false;
      return rootState.authentication.user?.tempAccount || rootState.authentication.user?.isRegistered;
    },
    paymentProvider: (): PaymentProvider => {
      const paymentProvider: string | PaymentProvider =
        process.env.VUE_APP_PAYMENT_PROVIDER || PaymentProvider.TRANSACTBRIDGE;
      return PaymentProvider[paymentProvider.toUpperCase() as keyof typeof PaymentProvider];
    },
  },
  mutations: {
    SHOW_PAY_POPUP: (state) => {
      state.showPayPopup = true;
      document.querySelector("html")?.classList.add("noscroll");
      window.scrollTo(0, 0);
      StatsManager.SendNavigationStats(window.location.href, "PAY_POPUP");
    },
    CLOSE_PAY_POPUP: (state) => {
      state.showPayPopup = false;
      document.querySelector("html")?.classList.remove("noscroll");
      StatsManager.SendNavigationStats("PAY_POPUP", window.location.href);
    },
    SHOW_PAYEMENT_COMPLETED_POPUP: (state) => {
      state.showPaymentCompletedPopup = true;
      document.querySelector("html")?.classList.add("noscroll");
      window.scrollTo(0, 0);
      StatsManager.SendNavigationStats(window.location.href, "PAYMENT_COMPLETED_POPUP");
    },
    CLOSE_PAYEMENT_COMPLETED_POPUP: (state) => {
      state.showPaymentCompletedPopup = false;
      document.querySelector("html")?.classList.remove("noscroll");
      StatsManager.SendNavigationStats("PAYMENT_COMPLETED_POPUP", window.location.href);
    },
    SHOW_PAYEMENT_FAILED_POPUP: (state) => {
      state.showPaymentFailedPopup = true;
      document.querySelector("html")?.classList.add("noscroll");
      window.scrollTo(0, 0);
      StatsManager.SendNavigationStats(window.location.href, "PAYMENT_FAILED_POPUP");
    },
    CLOSE_PAYEMENT_FAILED_POPUP: (state) => {
      state.showPaymentFailedPopup = false;
      document.querySelector("html")?.classList.remove("noscroll");
      StatsManager.SendNavigationStats("PAYMENT_FAILED_POPUP", window.location.href);
    },
    SET_LAST_REQUESTED_GAME: (state, game: Game) => {
      state.lastRequestedGame = game;
    },
  },
  actions: {
    async setup(context): Promise<void> {
      const { getters, rootDispatch } = moduleActionContext(context, module);

      StatsManager.SendArbitraryStats("payment", {
        event: "payment_setup",
        provider: getters.paymentProvider,
      });

      switch (getters.paymentProvider) {
        case PaymentProvider.FASTSPRING:
          rootDispatch.fastspring.setup();
          break;
        case PaymentProvider.TRANSACTBRIDGE:
          rootDispatch.transactbridge.setup();
          break;
        default:
          console.error("Unknown payment provider");
      }
    },
    async purchaseSubscription(context): Promise<void> {
      const { getters, rootDispatch } = moduleActionContext(context, module);

      StatsManager.SendArbitraryStats("payment", {
        event: "purchase_started",
        provider: getters.paymentProvider,
      });

      switch (getters.paymentProvider) {
        case PaymentProvider.FASTSPRING:
          rootDispatch.fastspring.purchaseSubscription();
          break;
        case PaymentProvider.TRANSACTBRIDGE:
          rootDispatch.transactbridge.purchaseSubscription();
          break;
        default:
          console.error("Unknown payment provider");
      }
    },
    async onPurchaseCompleted(context): Promise<void> {
      const { commit, rootDispatch, state } = moduleActionContext(context, module);

      commit.SHOW_PAYEMENT_COMPLETED_POPUP();
      rootDispatch.games.checkAllsubscriptions();
      if (state.lastRequestedGame)
        rootDispatch.games.checkGameSubscription({ game: state.lastRequestedGame, force: true });
      StatsManager.SendArbitraryStats("payment", {
        event: "purchase_completed",
      });
    },
    async onPurchaseFailed(context): Promise<void> {
      const { commit } = moduleActionContext(context, module);

      commit.SHOW_PAYEMENT_FAILED_POPUP();
      StatsManager.SendArbitraryStats("payment", {
        event: "purchase_failed",
      });
    },
  },
});

export default module;
const mod1GetterContext = (args: [any, any, any, any]) => moduleGetterContext(args, module);
