import { defineModule } from "direct-vuex";
import { RawLocation } from "vue-router";
import { AxiosResponse } from "axios";
import jwtDecode, { JwtPayload } from "jwt-decode";

import Store, { moduleActionContext, axiosInstance as axios } from "@/store/";
import Router from "@/router";
import { SendMessageToServiceWorker, SERVICE_WORKER_OUTGOING_MESSAGE_TYPE } from "@/store/modules/mobile";
import i18n from "@/plugins/i18n";

import * as StatsManager from "@/../common/stats/StatsManager";
import { SubscriptionAccess } from "./games";

export enum UserPermission {
  SEE_BASIC_WEBRTC_STATS = "SEE_BASIC_WEBRTC_STATS",
  SEE_FULL_WEBRTC_STATS = "SEE_FULL_WEBRTC_STATS",
  SKIP_ADD2HOME = "SKIP_ADD2HOME",
  SKIP_SPEEDTEST = "SKIP_SPEEDTEST",
  BIGSCREEN = "BIGSCREEN",
}

export interface MeResponse {
  id: string;
  isRegistered: boolean;
  username?: string;
  favoriteGames: string[];
  lastPlayedGames: string[];
  permissions: UserPermission[];
  referralCode: string;
  hasReferrer: boolean;
  createdAt: string;
  gaveAgreement: boolean;
  tempAccount: boolean;
}

export interface User {
  id: string;
  uUID?: string;
  username?: string;
}

export interface CurrentUser extends User {
  isRegistered: boolean;
  permissions: UserPermission[];
  referralCode: string;
  hasReferrer: boolean;
  createdAt: Date;
  gaveAgreement: boolean;
  tempAccount: boolean;
}

class CGToken {
  jwt: string;

  constructor(jwt: string) {
    this.jwt = jwt;
  }

  get expired(): boolean {
    try {
      const payload = jwtDecode<JwtPayload>(this.jwt);
      return payload.exp ? payload.exp * 1000 < Date.now() : false;
    } catch (e) {
      return true;
    }
  }

  get valid(): boolean {
    try {
      jwtDecode<JwtPayload>(this.jwt);
      return true;
    } catch (e) {
      return false;
    }
  }
}

export interface AuthenticationState {
  user: CurrentUser | null;
  friendList: User[];
  jwt: string | undefined;
  cgtokens: CGToken[];
  showNeedAccessPopup: boolean;
  showNeedPartnerAuthenticationPopup: boolean;
  referralCode: string | undefined;
}

const TOKEN_KEY = "token";
const CGTOKENS_KEY = "cgtokens";
const CACHE_KEY = "auth";
const LOCAL_STORAGE_REFERRAL_KEY = "referral";
let cacheStorage: Cache | undefined;

let whoamiPromise: Promise<AxiosResponse<MeResponse>> | undefined = undefined;

const module = defineModule({
  namespaced: true as const,
  state: {
    user: null,
    jwt: undefined,
    cgtokens: [],
    showNeedAccessPopup: false,
    showNeedPartnerAuthenticationPopup: false,
    friendList: [],
    referralCode: undefined,
  } as AuthenticationState,
  getters: {
    isAuthenticated: (state): boolean => !!state.user,
    /**
     * Get the JWT token
     */
    jwt: (state): string | undefined => state.jwt || localStorage.getItem(TOKEN_KEY) || undefined,
    hasPermission: (state) => (permission: UserPermission): boolean =>
      state.user?.permissions.includes(permission) || false,
    isAuthPartner: (_state): boolean => {
      if (!process.env.VUE_APP_POPUP_URL_AUTH) return false;
      return !/^FALSE$/i.test(process.env.VUE_APP_POPUP_URL_AUTH);
    },
    isUrlSubPartner: (_state): boolean => {
      if (!process.env.VUE_APP_POPUP_URL_SUB) return false;
      return !/^FALSE$/i.test(process.env.VUE_APP_POPUP_URL_SUB);
    },
    isOtherSubPartner: (_state): boolean => {
      if (!process.env.VUE_APP_POPUP_HOW_TO_SUB) return false;
      return !/^FALSE$/i.test(process.env.VUE_APP_POPUP_HOW_TO_SUB);
    },
    referralCode: (state): string | undefined =>
      state.referralCode || localStorage.getItem(LOCAL_STORAGE_REFERRAL_KEY) || undefined,
    showSubscribeBanner: (_state): boolean => /^true$/i.test(process.env.VUE_APP_SHOW_SUBSCRIBE_BANNER),
  },
  mutations: {
    SET_JWT(state: AuthenticationState, jwt: string) {
      state.jwt = jwt;
      localStorage.setItem(TOKEN_KEY, jwt);
      StatsManager.setCGToken(jwt);
    },
    ADD_CGTOKEN(state: AuthenticationState, cgtoken: CGToken) {
      // add only new tokens
      if (state.cgtokens.find((t) => t.jwt === cgtoken.jwt)) return;
      // don't add expired and invalid tokens
      if (!cgtoken.valid || cgtoken.expired) return;

      state.cgtokens.push(cgtoken);

      localStorage.setItem(CGTOKENS_KEY, JSON.stringify(state.cgtokens.map((t) => t.jwt)));
    },
    REMOVE_CGTOKEN(state: AuthenticationState, cgtoken: CGToken) {
      state.cgtokens = state.cgtokens.filter((t) => t.jwt !== cgtoken.jwt);

      localStorage.setItem(CGTOKENS_KEY, JSON.stringify(state.cgtokens.map((t) => t.jwt)));
    },
    SET_CGTOKENS(state: AuthenticationState, cgtokens: CGToken[]) {
      // remove expired and invalid tokens
      state.cgtokens = cgtokens.filter((t) => t.valid && !t.expired);

      localStorage.setItem(CGTOKENS_KEY, JSON.stringify(state.cgtokens.map((t) => t.jwt)));
    },
    DELETE_JWT(state: AuthenticationState) {
      state.jwt = undefined;
      localStorage.removeItem(TOKEN_KEY);
      cacheStorage?.delete(TOKEN_KEY);
    },
    SET_USER(state: AuthenticationState, user: CurrentUser | null) {
      state.user = user;
      if (user) {
        StatsManager.setUser(user);
        SendMessageToServiceWorker(SERVICE_WORKER_OUTGOING_MESSAGE_TYPE.UPDATE_STATS_INFO, { user_id: user.id });
      }
    },
    SET_SHOW_NEED_ACCESS_POPUP(state: AuthenticationState, show: boolean) {
      state.showNeedAccessPopup = show;
      if (show) StatsManager.SendNavigationStats(location.href, "NEED_ACCESS_POPUP");
      else StatsManager.SendNavigationStats("NEED_ACCESS_POPUP", location.href);
    },
    SET_SHOW_NEED_PARTNER_AUTHENTICATION_POPUP(state: AuthenticationState, show: boolean) {
      state.showNeedPartnerAuthenticationPopup = show;
      if (show) StatsManager.SendNavigationStats(location.href, "NEED_PARTNER_AUTHENTICATION_POPUP");
      else StatsManager.SendNavigationStats("NEED_PARTNER_AUTHENTICATION_POPUP", location.href);
    },
    SET_USERNAME(state: AuthenticationState, username: string) {
      if (!state.user) return;
      state.user.username = username;
    },
    ADD_FRIENDS(state: AuthenticationState, friends: User[]) {
      // add only new friends
      state.friendList = friends.filter((newFriend) => !state.friendList.find((friend) => friend.id === newFriend.id));
    },
    REMOVE_FRIEND(state: AuthenticationState, friend: User) {
      state.friendList = state.friendList.filter((f) => f.id !== friend.id);
    },
    SET_REFERRAL_CODE(state: AuthenticationState, referralCode: string) {
      state.referralCode = referralCode;
      localStorage.setItem(LOCAL_STORAGE_REFERRAL_KEY, referralCode);
    },
    GIVE_AGREEMENT(state: AuthenticationState) {
      if (!state.user) return;
      state.user.gaveAgreement = true;
    },
  },
  actions: {
    logout(context) {
      const { state, getters, commit } = moduleActionContext(context, module);
      if (getters.jwt) commit.REMOVE_CGTOKEN(new CGToken(getters.jwt));
      cacheStorage?.put(CGTOKENS_KEY, new Response(JSON.stringify(state.cgtokens.map((t) => t.jwt))));

      commit.SET_USER(null);
      commit.DELETE_JWT();
    },
    async createTempAccount(context) {
      const { getters, dispatch } = moduleActionContext(context, module);
      try {
        const body: any = {};
        if (getters.referralCode) body.referralCode = getters.referralCode;
        const urlParams: { [key: string]: string } = {};
        // Get the current URK params, and transform them into a key-value object
        for (const [key, value] of new URLSearchParams(window.location.search).entries()) urlParams[key] = value;
        body.urlParams = urlParams;
        const response = await axios.post<{ token: string }>(`/auth/user/temp`, body);
        await dispatch.setJWT(response.data.token);
        await dispatch.whoami(true);
      } catch (e) {
        console.error(e);
      }
    },
    async tempRegister(context, { login, password, username }: { login: string; password: string; username?: string }) {
      const { dispatch } = moduleActionContext(context, module);
      try {
        const response = await axios.post<{ token: string }>(`/auth/user/temp/register`, { login, password, username });
        await dispatch.setJWT(response.data.token);
        await dispatch.whoami(true);
        StatsManager.SendArbitraryStats("auth", { event: "register", status: "success" });
      } catch (e) {
        StatsManager.SendArbitraryStats("auth", { event: "register", status: "fail" });
        console.error(e);
      }
    },
    async login(context, { login, password }: { login: string; password: string }): Promise<boolean> {
      const { dispatch, rootDispatch } = moduleActionContext(context, module);
      try {
        const response = await axios.post<{ token: string }>(`/auth/user/login`, { login, password });
        await dispatch.setJWT(response.data.token);
        await dispatch.whoami(true);
        await rootDispatch.games.checkAllsubscriptions();
        StatsManager.SendArbitraryStats("auth", { event: "login", status: "success" });
        return true;
      } catch (e) {
        StatsManager.SendArbitraryStats("auth", { event: "login", status: "fail" });
        console.error(e);
        return false;
      }
    },
    async loginValidity(_context, { login }: { login: string }): Promise<boolean> {
      try {
        await axios.post(`/auth/user/login/validity`, { login });
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async usernameValidity(_context, { username }: { username: string }): Promise<boolean> {
      try {
        await axios.post(`/auth/user/username/validity`, { username });
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async whoami(context, force?: boolean): Promise<AxiosResponse<MeResponse> | undefined> {
      const { state, commit, getters, dispatch, rootCommit, rootDispatch } = moduleActionContext(context, module);

      await dispatch.initCacheStorage();
      if (!getters.jwt) return;
      try {
        if (whoamiPromise && !force) return whoamiPromise;

        // check if current cgtoken is expired, or invalid, and if yes don't even try it
        const currentCGToken = new CGToken(getters.jwt);
        if (currentCGToken.expired || !currentCGToken.valid) {
          throw { message: "Expired or Invalid token", response: { status: 403 } };
        }

        await (whoamiPromise = axios.get<MeResponse>(`/auth/user/me`));
        const response = await whoamiPromise;
        commit.SET_USER({
          id: response.data.id,
          isRegistered: response.data.isRegistered,
          username: response.data.username,
          permissions: response.data.permissions,
          referralCode: response.data.referralCode,
          hasReferrer: response.data.hasReferrer,
          createdAt: new Date(response.data.createdAt),
          gaveAgreement: response.data.gaveAgreement,
          tempAccount: response.data.tempAccount,
        });
        rootCommit.games.SET_FAVORITE_GAMES_IDS(response.data.favoriteGames);
        rootDispatch.games.getFavoriteGames();
        rootCommit.games.SET_LAST_PLAYED_GAMES_IDS(response.data.lastPlayedGames);
        rootDispatch.games.getLastPlayedGames();
        dispatch.getFriends();

        // wait 5 seconds
        // await new Promise((resolve) => setTimeout(resolve, 5000));

        const accountAge = new Date().getTime() - new Date(response.data.createdAt).getTime();
        const maxAgeForReferrer = 1000 * 60 * 60 * 24 * 7; // 7 days
        // if the user has no referrer and is not too old
        if (!state.user?.hasReferrer && accountAge < maxAgeForReferrer) {
          if (getters.referralCode) {
            try {
              dispatch.updateReferrer();
            } catch (e) {
              console.error(e);
            }
          }
        }
      } catch (e) {
        if (e.response.status === 403) {
          dispatch.logout();
          whoamiPromise = undefined;
          // use the cgtokens to try to login again
          if (state.cgtokens.length > 0) {
            const latestToken = state.cgtokens[state.cgtokens.length - 1];
            commit.SET_JWT(latestToken.jwt);
            await dispatch.whoami(true);
          }
        }
      }
      return;
    },
    async initCacheStorage(context): Promise<void> {
      const { state, commit } = moduleActionContext(context, module);

      const cgTokens = localStorage.getItem(CGTOKENS_KEY);
      if (cgTokens && state.cgtokens.length === 0) {
        commit.SET_CGTOKENS(JSON.parse(cgTokens).map((t: string) => new CGToken(t)));
      }

      if (!window.caches) return;
      if (!cacheStorage) cacheStorage = await caches.open(CACHE_KEY);
      const cacheResponse = await cacheStorage.match(TOKEN_KEY);
      if (cacheResponse) {
        const token: string = await cacheResponse.json();
        // Don't commit if the token is already set
        if (!state.jwt) commit.SET_JWT(token);
      }
      const cacheResponse2 = await cacheStorage.match(CGTOKENS_KEY);
      if (cacheResponse2) {
        const cgtokens: string[] = await cacheResponse2.json();
        // Don't commit if the cgtokens are already set
        if (state.cgtokens.length === 0) {
          commit.SET_CGTOKENS(cgtokens.map((t: string) => new CGToken(t)));
        }
      }
    },
    async setJWT(context, jwt: string) {
      const { state, getters, commit, dispatch } = moduleActionContext(context, module);

      // if there's already a token and it's not the same
      if (getters.jwt && getters.jwt !== jwt) {
        // call the new function to convert the account
        const result = await dispatch.convertAccount(jwt);
        // if the account was converted, we remove the new token from the url and return without setting it
        if (result) {
          // remove the token from the url without reloading the page
          const currentURL = new URL(window.location.href);
          currentURL.searchParams.delete("cgtoken");
          const newRoute: RawLocation = { path: location.pathname, query: Object.fromEntries(currentURL.searchParams) };
          Router.replace(newRoute);
          // return without setting the token
          return;
        }
        // if the account was not converted, we continue and set the token as usual
      }

      await dispatch.initCacheStorage();
      commit.SET_JWT(jwt);
      commit.ADD_CGTOKEN(new CGToken(jwt));
      await cacheStorage?.put(TOKEN_KEY, new Response(JSON.stringify(jwt)));
      await cacheStorage?.put(CGTOKENS_KEY, new Response(JSON.stringify(state.cgtokens.map((t) => t.jwt))));
    },
    async updateUsername(context, username: string) {
      const { commit } = moduleActionContext(context, module);
      try {
        await (whoamiPromise = axios.post(`/auth/user/updateUsername`, { username: username }));
        commit.SET_USERNAME(username);
      } catch (e) {
        console.error(e);
      }
    },
    async getFriends(context) {
      const { commit } = moduleActionContext(context, module);
      try {
        const response = await axios.get<
          {
            _id: string;
            username: string;
            uUID: string;
          }[]
        >(`/api/v1/friends`);
        const users = [];
        for (const user of response.data) {
          users.push({
            id: user._id,
            username: user.username,
            uUID: user.uUID,
          });
        }
        commit.ADD_FRIENDS(users);
      } catch (e) {
        console.error(e);
      }
    },
    async addFriend(context, user: User) {
      const { commit } = moduleActionContext(context, module);
      try {
        await axios.post(`/api/v1/friends/${user.id}`);
        commit.ADD_FRIENDS([user]);
      } catch (e) {
        console.error(e);
      }
    },
    async removeFriend(context, user: User) {
      const { commit } = moduleActionContext(context, module);
      try {
        await axios.delete(`/api/v1/friends/${user.id}`);
        commit.REMOVE_FRIEND(user);
      } catch (e) {
        console.error(e);
      }
    },
    async needAccess(context) {
      const { commit, dispatch, getters } = moduleActionContext(context, module);
      const partnerAccess = getters.isAuthPartner;
      if (partnerAccess) {
        const onClose: () => void = () => {
          if (getters.isAuthenticated) {
            commit.SET_SHOW_NEED_PARTNER_AUTHENTICATION_POPUP(false);
            Router.replace("/");
          } else dispatch.openAuthPopup({ onClose });
        };
        const popupOpened = await dispatch.openAuthPopup({ onClose });
        if (!popupOpened) commit.SET_SHOW_NEED_PARTNER_AUTHENTICATION_POPUP(true);
      } else {
        commit.SET_SHOW_NEED_ACCESS_POPUP(true);
      }
    },
    async needSubscription(context) {
      const { commit, getters, rootDispatch, rootCommit, rootGetters } = moduleActionContext(context, module);
      const partnerUrlAccess = getters.isUrlSubPartner;
      const partnerOtherAccess = getters.isOtherSubPartner;

      // Use CG Payment is the user is a temp account or a registered (has CG login/password) user.
      // If the user is none of these, it's a user coming from a partner and they will subscribe through the partner.
      if (rootGetters.payment.enabled && rootGetters.payment.shouldUseCGPayement) {
        rootCommit.payment.SHOW_PAY_POPUP();
      } else if (partnerUrlAccess) {
        await rootDispatch.games.openSubPopup();
      } else if (partnerOtherAccess) {
        const highestExpiredSub = rootGetters.games.getHighestExpiredSubscription;
        if (highestExpiredSub?.access === SubscriptionAccess.FULL) {
          rootCommit.mobile.SHOW_RENEW_TUTO_POPUP();
        } else {
          rootCommit.mobile.SHOW_SUBSCRIBE_TUTO_POPUP();
        }
      } else {
        commit.SET_SHOW_NEED_ACCESS_POPUP(true);
      }
    },
    async setReferrer(context, referralCode: string) {
      const { commit } = moduleActionContext(context, module);
      commit.SET_REFERRAL_CODE(referralCode);
    },
    async updateReferrer(context) {
      const { getters } = moduleActionContext(context, module);
      if (!getters.referralCode) return;
      await axios.post(`/auth/user/setReferrer`, { referralCode: getters.referralCode });
    },
    async giveAgreement(context) {
      const { commit } = moduleActionContext(context, module);
      try {
        await axios.post(`/auth/user/giveAgreement`);
        commit.GIVE_AGREEMENT();
      } catch (e) {
        console.error(e);
      }
    },
    async convertAccount(_context, token): Promise<boolean> {
      try {
        await axios.patch(`/auth/user/convertAccount`, { token });
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    openAuthPopup(
      context,
      {
        onCGToken,
        onClose,
        onError,
      }: { onCGToken?: (cgToken: string) => void; onClose?: () => void; onError?: (error: string) => void }
    ): Window | null {
      onPopupCGToken = onCGToken;
      onPopupClose = onClose;
      onPopupError = onError;

      StatsManager.SendNavigationStats(location.href, process.env.VUE_APP_POPUP_URL_AUTH);
      if (/^true$/i.test(process.env.VUE_APP_POPUP_PARTNER_REDIRECT)) {
        location.replace(process.env.VUE_APP_POPUP_URL_AUTH);
        return null;
      }

      const popup = window.open(process.env.VUE_APP_POPUP_URL_AUTH);

      checkPopupClose(popup);
      return popup;
    },
    openUnsubPopup(
      context,
      onSuccess?: (success: boolean) => void,
      onClose?: () => void,
      onError?: (error: string) => void
    ): Window | null {
      onPopupSubUnsubSuccess = onSuccess;
      onPopupClose = onClose;
      onPopupError = onError;

      StatsManager.SendNavigationStats(location.href, process.env.VUE_APP_POPUP_URL_UNSUB);
      if (/^true$/i.test(process.env.VUE_APP_POPUP_PARTNER_REDIRECT)) {
        location.replace(process.env.VUE_APP_POPUP_URL_UNSUB);
        return null;
      }

      const popup = window.open(process.env.VUE_APP_POPUP_URL_UNSUB);

      checkPopupClose(popup);
      return popup;
    },
  },
});

function checkPopupClose(popup: Window | null): void {
  const popupInterval = setInterval(() => {
    if (popup?.closed) {
      if (!popupClosedProgrammatically) onPopupClose?.();
      clearInterval(popupInterval);
    }
  }, 100);
}

let onPopupCGToken: ((cgToken: string) => void) | undefined = undefined;
let onPopupError: ((error: string) => void) | undefined = undefined;
let onPopupSubUnsubSuccess: ((success: boolean) => void) | undefined = undefined;
let onPopupClose: (() => void) | undefined = undefined;
let popupClosedProgrammatically = false;

window.addEventListener(
  "message",
  async (event: MessageEvent) => {
    const authorizedOrigins = (process.env.VUE_APP_POPUP_URL_ORIGIN as string).split(";");

    if (authorizedOrigins.every((origin) => !new RegExp(event.origin).test(origin))) return;

    // An error has occured
    if (event.data.error) {
      popupClosedProgrammatically = true;
      (event.source as Window).close();

      if (event.data.error === "auth_fail") {
        console.error(event.data.error);
        Store.commit.SHOW_ALERT({
          text: i18n.t("popups.errors.auth_fail.text").toString(),
          onClose: () =>
            Store.dispatch.authentication.openAuthPopup({
              onCGToken: onPopupCGToken,
              onClose: onPopupClose,
              onError: onPopupError,
            }),
        });
        return;
      }

      console.error(event.data.error);
      onPopupError?.(event.data.error);
      onPopupClose?.();
      return;
    }

    // We received the token
    if (event.data.cgtoken) {
      popupClosedProgrammatically = true;
      (event.source as Window).close();

      await Store.dispatch.authentication.setJWT(event.data.cgtoken);
      await Store.dispatch.authentication.whoami(true);
      await Store.dispatch.games.init();

      onPopupCGToken?.(event.data.cgtoken);
      onPopupClose?.();
      return;
    }

    if (event.data.success !== undefined) {
      popupClosedProgrammatically = true;
      (event.source as Window).close();

      await Store.dispatch.games.init();

      onPopupSubUnsubSuccess?.(event.data.success);
      onPopupClose?.();
    }
  },
  false
);

export default module;
