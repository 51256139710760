import { isValidHttpUrl as ShareUtils } from "../utils/utils";

export interface ShareData {
  title: string;
  text: string;
  url: string;
}

export interface ShareOptions {
  referralCode?: string;
  title: string;
  text: string;
  url?: string;
}

/**
 * Get the URL to share
 * @param user
 * @param defaultURL
 */
export function GetShareURL(options: ShareOptions): string {
  const defaultURL = options.url || "FALSE";

  let url: URL;
  // If the share URL is an absolute URL, use it as is
  if (ShareUtils(defaultURL)) url = new URL(defaultURL);
  else {
    // If the share URL is FALSE, use the current URL
    if (defaultURL.match(/^FALSE$/i)) url = new URL(window.location.href);
    // Else, use the share URL as a relative URL to the current URL
    else url = new URL(defaultURL, window.location.href);
  }

  url.searchParams.set("share", "true");
  if (options.referralCode) url.searchParams.set("referrer", options.referralCode);
  url.searchParams.delete("cgtoken");
  return url.toString();
}

/**
 * Get the data to share
 * @param user The current user
 * @param game The game to share
 * @param defaultURL The default URL to share
 */
export function ShareData(options: ShareOptions): ShareData {
  return {
    title: options.title,
    text: options.text,
    url: GetShareURL(options),
  };
}

/**
 * Check if the browser can share
 * @param user The current user
 * @param defaultURL The default URL to share
 */
export function CanShare(options: ShareOptions): boolean {
  // Check if the browser supports the Web Share API
  if (!(navigator as any).canShare) return false;

  // Cast to any because typescript doesn't know about canShare yet
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (navigator as any).canShare(ShareData(options));
}

/**
 * Open the share dialog
 * @param game The game to share
 * @param user The current user
 * @param defaultURL The default URL to share
 */
export async function Share(options: ShareOptions): Promise<void> {
  return navigator.share(ShareData(options));
}
