

















































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import HorizontalScroll from "@/components/HorizontalScroll.vue";

@Component({
  components: {
    HorizontalScroll,
  },
})
export default class HomeScroller extends Vue {
  @Prop(String) readonly title: string;
  @Prop(String) readonly subTitle: string;
  @Prop({ type: Boolean, default: false }) readonly stepper: boolean;

  activeStepper = 0;

  $refs!: {
    horizontalScroll: Vue;
  };

  get stepperArray(): number[] {
    return Array.from(Array(this.childrenCount).keys());
  }

  childrenCount = 0;

  calculateStepper(): void {
    const el = this.$refs.horizontalScroll.$el;
    const maxScroll = el.scrollWidth - el.clientWidth;
    this.activeStepper = Math.floor(el.scrollLeft / (maxScroll / this.childrenCount));
    this.activeStepper = Math.min(this.activeStepper, this.childrenCount - 1);
    this.activeStepper = Math.max(this.activeStepper, 0);
  }

  mounted(): void {
    if (this.stepper) {
      this.childrenCount = this.$refs.horizontalScroll.$children.length;
      const el = this.$refs.horizontalScroll.$el;

      el.addEventListener("scroll", () => this.calculateStepper());
      el.addEventListener("touchend", () => this.calculateStepper());
    }
  }
}
