import { render, staticRenderFns } from "./leaderboard_FILL0_wght400_GRAD0_opsz48.vue?vue&type=template&id=34424502&scoped=true&"
var script = {}
import style0 from "./leaderboard_FILL0_wght400_GRAD0_opsz48.vue?vue&type=style&index=0&id=34424502&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34424502",
  null
  
)

export default component.exports