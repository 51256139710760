import { render, staticRenderFns } from "./HorizontalScroll.vue?vue&type=template&id=24d60b1e&scoped=true&"
import script from "./HorizontalScroll.vue?vue&type=script&lang=ts&"
export * from "./HorizontalScroll.vue?vue&type=script&lang=ts&"
import style0 from "./HorizontalScroll.vue?vue&type=style&index=0&id=24d60b1e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24d60b1e",
  null
  
)

export default component.exports