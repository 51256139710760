import { init, ApmBase } from "@elastic/apm-rum";

function uuidv4(): string {
  // Reference: https://stackoverflow.com/a/61011303/2780319
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (s) => {
    const c = Number.parseInt(s, 10);
    return (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16);
  });
}

let apm: ApmBase | undefined = undefined;

const pageName = "Lobby-page-load";
const SESSION_ID_KEY = "sessionID";
export const LAST_SEEN_DATE_KEY = "lastSeen";

export async function initApm(
  apmServerUrl: string,
  sessionExpirationDuration: number,
  distributedTracingOrigins: (string | RegExp)[] | undefined,
  active = true
): Promise<ApmBase> {
  apm = init({
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: "CareGame-SDK",
    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: apmServerUrl,
    // environment: "toto",
    active: active,
    breakdownMetrics: true,
    distributedTracingOrigins: distributedTracingOrigins,
    propagateTracestate: true,
    logLevel: "trace",
    ignoreTransactions: [`*/send_stat/gamer`],
    disableInstrumentations: ["fetch", "xmlhttprequest", "eventtarget"],
    flushInterval: 10,
    pageLoadTransactionName: pageName,
  });
  let session_id: string;
  const storedSessionID = localStorage.getItem(SESSION_ID_KEY);
  const lastSeen = localStorage.getItem(LAST_SEEN_DATE_KEY);
  if (
    !storedSessionID ||
    (lastSeen && (new Date().getTime() - new Date(lastSeen).getTime()) / 1000 > sessionExpirationDuration)
  )
    session_id = uuidv4();
  else session_id = storedSessionID;
  apm.addLabels({ session_id });
  apm.addLabels({
    isPwa:
      (window.navigator as any).standalone ||
      window.matchMedia("(display-mode: standalone)").matches ||
      window.matchMedia("(display-mode: fullscreen)").matches,
  });
  // setSessionID(session_id);
  localStorage.setItem(SESSION_ID_KEY, session_id);
  localStorage.setItem(LAST_SEEN_DATE_KEY, new Date().toISOString());
  return apm;
}

export default apm;
