import { defineModule } from "direct-vuex";

import { moduleActionContext } from "@/store/";
import VueI18n from "@/plugins/i18n";

import * as StatsManager from "@/../common/stats/StatsManager";

export interface Button {
  text: string;
  onClick: () => void;
  important?: boolean;
}

interface PopupPayload {
  /**
   * Internal name of the popup, used for tracking
   */
  name: string;
  title: string;
  text: string;
  buttons: Button[];
}

export interface PopupState {
  name: string;
  title: string;
  text: string;
  buttons: Button[];
  showPopup: boolean;
}

const module = defineModule({
  namespaced: true as const,
  state: {
    name: "",
    title: "",
    text: "",
    buttons: [],
    showPopup: false,
  } as PopupState,
  mutations: {
    SHOW_POPUP(state, payload: PopupPayload) {
      if (state.showPopup) console.warn(`Showing popup ${payload.name} while ${state.name} is already shown`);
      if (!payload.name) throw new Error("Popup name is required");

      state.name = payload.name;
      state.title = payload.title;
      state.text = payload.text;
      state.buttons = payload.buttons;

      state.showPopup = true;

      document.querySelector("html")?.classList.add("noscroll");
      window.scrollTo(0, 0);

      StatsManager.SendNavigationStats(window.location.href, state.name);
    },
    CLOSE_POPUP(state) {
      if (!state.showPopup) console.warn(`Closing popup while no popup is shown`);

      // Track the popup name before resetting the state
      const popupName = state.name;
      state.name = "";
      state.title = "";
      state.text = "";
      state.buttons = [];

      state.showPopup = false;

      document.querySelector("html")?.classList.remove("noscroll");

      StatsManager.SendNavigationStats(popupName, window.location.href);
    },
  },
  actions: {
    showAlreadySubPopup(context) {
      const { commit } = moduleActionContext(context, module);

      commit.SHOW_POPUP({
        name: "ALREADY_SUB_POPUP",
        title: VueI18n.t("alreadySubPopup.title").toString(),
        text: VueI18n.t("alreadySubPopup.text").toString(),
        buttons: [
          {
            text: VueI18n.t("alreadySubPopup.buttons.close").toString(),
            onClick: () => {
              StatsManager.SendButtonClickStats("ALREADY_SUB_POPUP", "close");
              commit.CLOSE_POPUP();
            },
            important: true,
          },
        ],
      });
    },
    showLostMySubPopup(context) {
      const { commit } = moduleActionContext(context, module);

      commit.SHOW_POPUP({
        name: "LOST_MY_SUB_POPUP",
        title: VueI18n.t("lostMySubPopup.title").toString(),
        text: VueI18n.t("lostMySubPopup.text").toString(),
        buttons: [
          {
            text: VueI18n.t("lostMySubPopup.buttons.close").toString(),
            onClick: () => {
              StatsManager.SendButtonClickStats("LOST_MY_SUB_POPUP", "close");
              commit.CLOSE_POPUP();
            },
            important: true,
          },
        ],
      });
    },
  },
});

export default module;
