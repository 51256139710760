import { defineModule } from "direct-vuex";

import { moduleActionContext, axiosInstance as axios } from "@/store/";
import { AxiosResponse } from "axios";

export interface Publisher {
  _id: string;
  name: string;
}

let publishersPromise: Promise<AxiosResponse<Publisher[]>> | undefined = undefined;

export interface PublishersState {
  publishers: Publisher[];
}

const module = defineModule({
  namespaced: true as const,
  state: {
    publishers: [],
  } as PublishersState,

  mutations: {
    SET_PUBLISHERS(state, publishers: Publisher[]) {
      state.publishers = publishers;
    },
  },
  actions: {
    async getPublishers(context): Promise<Publisher[]> {
      const { commit, state } = moduleActionContext(context, module);
      try {
        if (state.publishers.length > 0) return state.publishers;
        if (!publishersPromise) publishersPromise = axios.get<Publisher[]>(`/api/v1/publishers`);
        const { data } = await publishersPromise;
        commit.SET_PUBLISHERS(data);
        return state.publishers;
      } catch (error) {
        console.error(error);
        commit.SET_PUBLISHERS([]);
        return state.publishers;
      }
    },
  },
});

export default module;
