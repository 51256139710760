



































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import * as StatsManager from "@/../common/stats/StatsManager";

import RegisterForm from "@/components/Profile/RegisterForm.vue";
import LoginForm from "@/components/Profile/LoginForm.vue";

@Component({
  components: {
    RegisterForm,
    LoginForm,
  },
})
export default class SharePopup extends Vue {
  useRegister = true;

  toggleForm(): void {
    this.useRegister = !this.useRegister;
    StatsManager.SendButtonClickStats("REGISTER_POPUP", "toggleForm");
  }

  close(): void {
    this.$store.direct.commit.mobile.CLOSE_REGISTER_POPUP();
    this.$store.direct.commit.payment.SHOW_PAY_POPUP();
    StatsManager.SendButtonClickStats("REGISTER_POPUP", "close");
  }

  async afterConnected(): Promise<void> {
    // Check if user is already subscribed
    const hasFullSub = this.$store.direct.state.games.subscriptions.some((sub) => sub.access === "full");

    this.$store.direct.commit.mobile.CLOSE_REGISTER_POPUP();

    if (!hasFullSub) this.$store.direct.dispatch.payment.purchaseSubscription();
    else this.$store.direct.dispatch.popup.showAlreadySubPopup();
  }

  async registered(): Promise<void> {
    StatsManager.SendButtonClickStats("REGISTER_POPUP", "register");
    await this.afterConnected();
  }

  async logged(): Promise<void> {
    StatsManager.SendButtonClickStats("REGISTER_POPUP", "login");
    await this.afterConnected();
  }
}
