import { defineModule } from "direct-vuex";

import { LocaleMessageObject } from "vue-i18n";

import { moduleActionContext, axiosInstance as axios } from "@/store/";
import VueI18n from "@/plugins/i18n";
import { AxiosResponse } from "axios";

type Locale = "fr" | "en" | string;

interface GameListLocalizedClientInfo {
  title: string;
}

export interface GameList {
  _id: string;
  name: string;
  urlSegment: string;
  games: string[];
  localizedClientInfo: { [key in Locale]: GameListLocalizedClientInfo };
}

let gameListsPromise: Promise<AxiosResponse<GameList[]>> | undefined = undefined;

export interface GameListsState {
  gameLists: GameList[];
}

const module = defineModule({
  namespaced: true as const,
  state: {
    gameLists: [],
  } as GameListsState,
  mutations: {
    SET_GAME_LISTS(state, gameLists: GameList[]) {
      state.gameLists = gameLists;
    },
  },
  actions: {
    async getGameLists(context): Promise<GameList[]> {
      const { rootGetters, state, commit } = moduleActionContext(context, module);
      try {
        if (state.gameLists.length > 0) return state.gameLists;
        if (!gameListsPromise) gameListsPromise = axios.get<GameList[]>(`/api/v1/gamelists`);
        const { data } = await gameListsPromise;
        commit.SET_GAME_LISTS(data);
        const locales: Locale[] = rootGetters.language.availableLanguageCodes;
        locales.forEach((locale) => {
          const messages: LocaleMessageObject = {};
          state.gameLists.forEach(
            (a) => (messages[`gameLists.${a._id}.title`] = a?.localizedClientInfo?.[locale]?.title)
          );
          VueI18n.mergeLocaleMessage(locale, messages);
        });
        return state.gameLists;
      } catch (error) {
        console.error(error);
        commit.SET_GAME_LISTS([]);
        return state.gameLists;
      }
    },
  },
});

export default module;
