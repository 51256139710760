


















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class CardM extends Vue {
  @Prop(String) readonly image: string;
}
