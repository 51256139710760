


































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { HomeSectionCategoryList, HomeSectionListedCategory } from "@/store/modules/homesections";

import CardCategory from "@/components/CardCategory.vue";

@Component({
  components: {
    CardCategory,
  },
})
export default class CategoryList extends Vue {
  @Prop(Object) readonly data: HomeSectionCategoryList;
  @Prop(String) readonly title: string;

  get categories(): HomeSectionListedCategory[] {
    return this.data.listedCategories.slice().sort((a, b) => a.order - b.order);
  }
}
