





































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import * as StatsManager from "@/../common/stats/StatsManager";

@Component
export default class SharePopup extends Vue {
  close(): void {
    this.$store.direct.commit.payment.CLOSE_PAYEMENT_COMPLETED_POPUP();
    StatsManager.SendButtonClickStats("PAYMENT_COMPLETED_POPUP", "close");
  }
}
