import { defineModule } from "direct-vuex";
import axios, { AxiosResponse } from "axios";

import Vuei18n from "@/plugins/i18n";
import { moduleActionContext, axiosInstance } from "@/store";

interface Language {
  code: string;
  jsonLocaleFileUrl: string;
}

export interface LanguageState {
  language?: string;
  availableLanguages: Language[];
}

let promise: Promise<AxiosResponse<Language[]>> | undefined;

const module = defineModule({
  namespaced: true as const,
  state: {
    language: undefined,
    availableLanguages: [],
  } as LanguageState,
  getters: {
    availableLanguageCodes: (state) => state.availableLanguages.map((language) => language.code),
  },
  mutations: {
    SET_LANGUAGE(state: LanguageState, lang: string) {
      state.language = lang;
      Vuei18n.locale = lang;
    },
    SET_AVAILABLE_LANGUAGES(state: LanguageState, availableLanguages: Language[]) {
      state.availableLanguages = availableLanguages;
    },
  },
  actions: {
    setLanguage(context, prefferdLanguages: readonly string[]) {
      const { getters, commit } = moduleActionContext(context, module);

      if (typeof prefferdLanguages === "string") commit.SET_LANGUAGE(prefferdLanguages);
      else {
        let language: string | undefined = undefined;
        prefferdLanguages?.some((l) =>
          getters.availableLanguageCodes.some((sl): boolean => {
            const match = new RegExp(sl, "gi").test(l);
            if (match) language = sl;
            return match;
          })
        );
        if (!language) language = Vuei18n.fallbackLocale.toString();
        commit.SET_LANGUAGE(language);
      }
    },
    async init(context): Promise<void> {
      const { state, commit, dispatch } = moduleActionContext(context, module);

      if (state.availableLanguages.length !== 0) return;
      if (promise) return;
      promise = axiosInstance.get<Language[]>(`/api/v1/languages`);
      const response = await promise;
      commit.SET_AVAILABLE_LANGUAGES(response.data);

      await dispatch.setLanguage(navigator.languages);

      await dispatch.loadMessages();
    },
    async loadMessages(context) {
      const { state } = moduleActionContext(context, module);

      for (const language of state.availableLanguages) {
        try {
          if (!language.jsonLocaleFileUrl) return;
          const response = await axios.get(language.jsonLocaleFileUrl);
          Vuei18n.mergeLocaleMessage(language.code, response.data);
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
});

export default module;
