






































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import * as StatsManager from "@/../common/stats/StatsManager";

@Component
export default class SharePopup extends Vue {
  close(): void {
    this.$store.direct.commit.payment.CLOSE_PAY_POPUP();
    StatsManager.SendButtonClickStats("PAY_POPUP", "close");
  }

  subscribe(): void {
    const tempAccount = this.$store.direct.state.authentication.user?.tempAccount;
    const skipRegister = this.$store.direct.getters.payment.skipRegister;

    // If the user has a temp account, we ask them to register
    if (tempAccount && !skipRegister) {
      this.$store.direct.commit.payment.CLOSE_PAY_POPUP();
      this.$store.direct.commit.mobile.SHOW_REGISTER_POPUP();
    }
    // Otherwise, they can purchase
    else {
      this.$store.direct.dispatch.payment.purchaseSubscription();
      this.$store.direct.commit.payment.CLOSE_PAY_POPUP();
    }

    StatsManager.SendButtonClickStats("PAY_POPUP", "subscribe");
  }
}
