



























import { Game } from "@/store/modules/games";
import { Component, Prop, Vue } from "vue-property-decorator";
import BonusCard from "./BonusCard.vue";

@Component({
  components: {
    BonusCard,
  },
})
export default class BonusScroller extends Vue {
  @Prop({ type: Object, required: true }) game: Game;
}
