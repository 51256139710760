import { defineModule } from "direct-vuex";

import { LocaleMessageObject } from "vue-i18n";

import { moduleActionContext, axiosInstance as axios } from "@/store/";
import VueI18n from "@/plugins/i18n";
import { AxiosResponse } from "axios";

interface GenreLocalizedClientInfo {
  name: string;
  bannerImg: string;
}

type Locale = "fr" | "en" | string;

export class Genre {
  _id: string;
  name: string;
  enabled: boolean;
  headerImg: string;
  localizedClientInfo: { [key in Locale]: GenreLocalizedClientInfo };
  colorGradient1?: string;
  colorGradient2?: string;
  urlSegment?: string;
}

let genresPromise: Promise<AxiosResponse<Genre[]>> | undefined = undefined;

export interface GenresState {
  genres: Genre[];
}

const module = defineModule({
  namespaced: true as const,
  state: {
    genres: [],
  } as GenresState,
  mutations: {
    SET_GENRES(state, genres: Genre[]) {
      state.genres = genres;
    },
  },
  actions: {
    async getGenres(context): Promise<Genre[]> {
      const { rootGetters, state, commit } = moduleActionContext(context, module);
      try {
        if (state.genres.length > 0) return state.genres;
        if (!genresPromise) genresPromise = axios.get<Genre[]>(`/api/v1/genres`);
        const { data } = await genresPromise;
        commit.SET_GENRES(data);
        const locales: Locale[] = rootGetters.language.availableLanguageCodes;
        locales.forEach((locale) => {
          const messages: LocaleMessageObject = {};
          state.genres.forEach((a) => (messages[`genres.${a._id}.name`] = a?.localizedClientInfo?.[locale]?.name));
          VueI18n.mergeLocaleMessage(locale, messages);
        });
        return state.genres;
      } catch (error) {
        console.error(error);
        commit.SET_GENRES([]);
        return state.genres;
      }
    },
  },
});

export default module;
