














































import { Game } from "@/store/modules/games";
import { Component, Prop, Vue } from "vue-property-decorator";
import * as StatsManager from "@/../common/stats/StatsManager";

@Component
export default class ShareButton extends Vue {
  @Prop({ type: Object }) game: Game | undefined;

  shareIsToggled = false;

  get canShare(): boolean {
    return this.$store.direct.getters.mobile.canShare;
  }

  async share(): Promise<void> {
    if (!this.game) return;
    this.$store.direct.dispatch.mobile.share({ game: this.game });
    StatsManager.SendButtonClickStats(location.href, "share");
  }
}
