
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Game } from "@/store/modules/games";

@Component
export default class CardGame extends Vue {
  @Prop(Object) readonly game: Game;

  get loaded(): boolean {
    return !!this.game;
  }

  get to(): string {
    return `/game/${this.game.appID}`;
  }
}
