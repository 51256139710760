






















































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import * as StatsManager from "@/../common/stats/StatsManager";

import LoginForm from "@/components/Profile/LoginForm.vue";

@Component({
  components: {
    LoginForm,
  },
})
export default class SharePopup extends Vue {
  close(): void {
    this.$store.direct.commit.mobile.CLOSE_LOGIN_POPUP();
    if (this.$store.direct.state.mobile.loginPopupCloseToWelcome)
      this.$store.direct.commit.mobile.SHOW_AGREEMENT_POPUP();
    StatsManager.SendButtonClickStats("LOGIN_POPUP", "close");
  }

  loginToPartner(): void {
    this.$store.direct.dispatch.authentication.needAccess();
    StatsManager.SendButtonClickStats("LOGIN_POPUP", "loginToPartner");
  }

  logged(): void {
    this.$store.direct.commit.mobile.CLOSE_LOGIN_POPUP();
    StatsManager.SendButtonClickStats("LOGIN_POPUP", "login");
    this.$store.direct.dispatch.games.checkAllsubscriptions();
  }
}
