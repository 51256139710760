










































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import CardGameM from "./CardGameM.vue";

import { Game } from "@/store/modules/games";

@Component({
  components: {
    CardGameM,
  },
})
export default class HomeListedGame extends Vue {
  @Prop(Boolean) readonly fake: boolean;
  @Prop(Object) readonly game: Game;
  @Prop(Boolean) readonly disableLink: boolean;
  @Prop(String) readonly subTitle: string;

  get loaded(): boolean {
    return !this.fake || !!this.game;
  }

  get to(): string {
    return `/game/${this.game.appID}`;
  }
}
