import { render, staticRenderFns } from "./account_circle_FILL1_wght400_GRAD0_opsz48.vue?vue&type=template&id=153a7d04&scoped=true&"
var script = {}
import style0 from "./account_circle_FILL1_wght400_GRAD0_opsz48.vue?vue&type=style&index=0&id=153a7d04&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "153a7d04",
  null
  
)

export default component.exports