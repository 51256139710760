export enum NetworkState {
  /**
   * The network is very good.
   * <br/>The quality is at maximum.
   */
  MAX = 1,

  /**
   * The network is good.
   * <br/>The quality is good but not at maximum.
   */
  GOOD = 0.66,

  /**
   * The network is bad.
   * <br/>The quality is medium and the user should be alerted to check their internet connection.
   */
  MEDIUM = 0.33,

  /**
   * The network is very bad.
   * <br/>The quality is at minimum and the user should be alerted to check their internet connection.
   */
  BAD = 0,
}
