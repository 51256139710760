


























































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import * as StatsManager from "@/../common/stats/StatsManager";

@Component
export default class UpdateModal extends Vue {
  async reload(): Promise<void> {
    await this.$store.direct.dispatch.mobile.reloadPWA();
    StatsManager.SendButtonClickStats("UPDATE_POPUP", "reload");
  }
}
