











































































import { Game, SubscriptionAccess } from "@/store/modules/games";
import { Component, Prop, Vue } from "vue-property-decorator";
import * as StatsManager from "@/../common/stats/StatsManager";

@Component
export default class PlayButton extends Vue {
  @Prop({ type: Boolean, default: true }) showPlayButton: boolean;
  @Prop({ type: Object }) game: Game | undefined;
  @Prop({ type: Number, default: 42 }) height: number;
  @Prop({ type: Number, default: 120 }) width: number;
  @Prop({ type: Number, default: 0 }) minWidth: number;
  @Prop({ type: Boolean }) stretch: boolean;
  @Prop({ type: String, default: "big" }) fontSize: "small" | "big";

  get playButtonText() {
    if (this.game?.isComingSoon) return this.$t(`game.buttons.play.coming-soon`).toString();
    if (!this.gameSubscription) return "";
    return this.$t(`game.buttons.play.${this.gameSubscription}`).toString();
  }

  get gameSubscription(): SubscriptionAccess | undefined {
    if (!this.game) return undefined;
    return this.$store.direct.getters.games.getGameSubscription(this.game) || SubscriptionAccess.VISIBLE;
  }

  get noActiveSub(): boolean {
    return this.gameSubscription === SubscriptionAccess.VISIBLE;
  }

  get buttonStyle() {
    let styles: any = {
      width: `${this.width}px`,
      height: `${this.height}px`,
      "min-width": `${this.minWidth}px`,
      "font-size": "12px",
    };
    if (this.stretch) styles["width"] = "100%";
    if (this.fontSize === "small") styles["font-size"] = "10px";
    return styles;
  }

  async play(e: Event): Promise<void> {
    e.preventDefault();
    e.stopPropagation();

    StatsManager.SendButtonClickStats(location.href, "play");

    if (!this.game) return;
    if (this.game?.isComingSoon) return;
    if (!this.$store.direct.getters.mobile.isBrowserValid) {
      this.$store.direct.commit.SHOW_ALERT({
        text: this.$t("alert.alerts.bad-browser.text").toString(),
        title: this.$t("alert.alerts.bad-browser.title").toString(),
      });
      StatsManager.SendNavigationStats(location.href, "INVALID_BROWSER_ALERT");
      return;
    }

    this.$store.direct.dispatch.games.play({game: this.game, playOnBigScreen: false});
  }
}
