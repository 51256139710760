















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import HomeScroller from "@/components/HomeScroller.vue";
import Banner from "@/components/Banner.vue";

import { Game } from "@/store/modules/games";
import { HomeSection, Type } from "@/store/modules/homesections";
import CardGameXL from "@/components/Home/HomeSections/CardGameXL.vue";
import CardGameL from "@/components/CardGameL.vue";
import CardShowtime from "@/components/Home/HomeSections/CardShowtime.vue";
import CategoryList from "@/components/CategoryList.vue";
import HomeCardGameVideo from "./HomeSections/HomeCardGameVideo.vue";

@Component({
  components: {
    CardGameXL,
    CardGameL,
    CardShowtime,
    CategoryList,
    HomeScroller,
    Banner,
    HomeCardGameVideo,
  },
})
export default class HomeSectionComponent extends Vue {
  @Prop(Object) readonly data: HomeSection;
  loaded = false;

  getGamesFromHomeSection(): Game[] {
    // Get a copy of the HomeSection games
    let homeSectionGames = this.data.games.slice();
    // Sort them based on the order defined in database
    homeSectionGames = homeSectionGames.sort((a, b) => a.order - b.order);

    // Fetch the actual games from the IDs
    let res = homeSectionGames.map(
      (g) => this.$store.direct.state.games.games.find((game) => game._id === g.game) as Game
    );
    // Remove null references (games that would have been deleted for example)
    res = res.filter((g) => g);
    return res;
  }

  get games(): Game[] {
    switch (this.data.type) {
      case Type.FAVORITE:
        return this.$store.direct.state.games.favoriteGames.slice(0, 10);
      case Type.LAST_PLAYED:
        return this.$store.direct.state.games.lastPlayedGames.slice(0, 10);
      default:
        return this.getGamesFromHomeSection();
    }
  }

  get atLeastOneGame(): boolean {
    return this.games.length > 0;
  }

  get isTypeBig(): boolean {
    return this.data.type === Type.BIG;
  }

  get isTypeFavorite(): boolean {
    return this.data.type === Type.FAVORITE;
  }

  get isTypeLastPlayed(): boolean {
    return this.data.type === Type.LAST_PLAYED;
  }

  get isTypeNormal(): boolean {
    return this.data.type === Type.NORMAL;
  }

  get isTypeCategoryList(): boolean {
    return this.data.type === Type.CATEGORY_LIST;
  }

  get isTypeCategorySingle(): boolean {
    return this.data.type === Type.CATEGORY_SINGLE;
  }

  get isTypeGameSingle(): boolean {
    return this.data.type === Type.GAME_SINGLE;
  }

  get isTypeGameVideo(): boolean {
    return this.data.type === Type.GAME_VIDEO;
  }

  get title(): string {
    return this.$te(`homeSections.${this.data._id}.title`)
      ? this.$t(`homeSections.${this.data._id}.title`).toString()
      : "";
  }

  get subTitle(): string {
    return this.$te(`homeSections.${this.data._id}.subTitle`)
      ? this.$t(`homeSections.${this.data._id}.subTitle`).toString()
      : "";
  }

  get isFirst(): boolean {
    return this.$store.direct.state.homeSections.homeSections[0]._id === this.data._id;
  }

  get showPlayButton(): boolean {
    return this.data.showPlayButton || false;
  }

  async created(): Promise<void> {
    if (this.data.name === "$fake") return;
    if (this.data.games.length > 0)
      await this.$store.direct.dispatch.games.getGamesByIds(this.data.games.map((g) => g.game));
    this.loaded = true;
  }

  transmitVideoCard() {
    let videoCard = this.$refs.videoCard;
    if (videoCard) {
      this.$emit('video-card-ready', (videoCard as Vue).$el);
    }
  }

  transmitVideoCardUpdate() {
    let videoCard = this.$refs.videoCard;
    if (videoCard) {
      this.$emit('video-card-update', (videoCard as Vue).$el);
    }
  }
}
