import { render, staticRenderFns } from "./keyboard_arrow_down_FILL0_wght0_GRAD0_opszNaN.vue?vue&type=template&id=5ef5f9ab&scoped=true&"
var script = {}
import style0 from "./keyboard_arrow_down_FILL0_wght0_GRAD0_opszNaN.vue?vue&type=style&index=0&id=5ef5f9ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ef5f9ab",
  null
  
)

export default component.exports